import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { CheckIcon } from "../../assets/icons";
import { useMutation } from "@apollo/client";
import { EDIT_PROJECT } from "../../graphql/mutation/project";

export default function RenameProject({
  handleClosePopup,
  open,
  setOpen,
  setSelectedMenu,
  pName,
  pId,
}) {
  const [projectName, setProjectName] = React.useState("");
  const re = new RegExp("^.{1,25}$");
  const [editProject] = useMutation(EDIT_PROJECT);

  const handleClose = () => {
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };

  const handleRenameProject = async () => {
    await editProject({
      variables: {
        id: pId,
        data: {
          name: projectName,
        },
      },
    });
    setSelectedMenu("");
    setProjectName("");
    handleClosePopup();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">Rename project {pName}?</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            placeholder={pName}
            onChange={(e) => {
              setProjectName(e.target.value);
            }}
            sx={{
              paddingTop: "12px",
            }}
            InputProps={{
              endAdornment: re.test(projectName) && (
                <InputAdornment position="end">
                  <CheckIcon />
                </InputAdornment>
              ),
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter" && re.test(projectName)) {
                ev.preventDefault();
                handleRenameProject();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleRenameProject()}
                autoFocus
                disabled={!re.test(projectName)}
              >
                Rename
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Grid, IconButton, Typography } from "@mui/material";
import { DnDIcon } from "../../assets/icons";
import CommandMenu from "../ProjectDetailView/CommandMenu";
import ProjectStatus from "../ProjectDetailView/ProjectStatus";

function DnDCommandCard({ command, categoryId, dndProps }) {
  const { ref, snapshot, dragProps, handleProps } = dndProps;
  return (
    <div ref={ref} snapshot={snapshot} {...dragProps} {...handleProps}>
      <Grid container alignItems="center" xs={12}>
        <Grid item xs={0.5}>
          <IconButton disabled>
            <DnDIcon />
          </IconButton>
        </Grid>
        <Grid
          xs={11}
          container
          item
          paddingLeft="22px"
          paddingRight="16px"
          minHeight="54px"
          display="flex"
          alignItems="center"
          style={{
            backgroundColor: "#FFF",
            borderRadius: "0px 4px 4px 0px",
            border: "1px solid #E3E3E3",
            // blue border only left side
            borderLeft: "1px solid #4C7DE7",
          }}
        >
          <Grid item xs={3} display="flex" alignItems="center" gap="14px">
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H9L7.37 18.45C6.93 19.11 7.4 20 8.2 20H13.8C14.6 20 15.08 19.11 14.63 18.45L13 16H20C21.1 16 22 15.1 22 14V2C22 0.9 21.1 0 20 0ZM20 12H2V3C2 2.45 2.45 2 3 2H19C19.55 2 20 2.45 20 3V12Z"
                fill="#4C7DE7"
              />
            </svg>

            <Typography variant="body1" color="#242426">
              {command.title}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <ProjectStatus status={command.status} />
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            justifyContent="end"
            alignItems="center"
            gap="24px"
          >
            <CommandMenu
              command={command}
              categoryId={categoryId}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default DnDCommandCard;

import React from "react";
import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { MY_COMPANY } from "../../graphql/query/company";
import { EDIT_COMPANY } from "../../graphql/mutation/company";
import { UserContext } from "../../context/UserProvider";
import { useSnackbar } from "notistack";
import InviteMemberPopup from "../TeamsPopups/InviteMemberPopup";
import DeleteMemberPopup from "../TeamsPopups/DeleteMemberPopup";

function TeamsTabPanel() {
  const { loading, data } = useQuery(MY_COMPANY);
  const { myProfile } = React.useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMemberID, setSelectedMemberID] = React.useState("");
  const [invitePopupOpen, setInvitePopupOpen] = React.useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);
  const [editMyCompany] = useMutation(EDIT_COMPANY);

  if (loading) return null;

  return (
    <Box>
      <Grid container marginTop="32px" columnSpacing="64px" rowSpacing="24px">
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="body1" fontWeight="500" color="#344054">
            Organization name
          </Typography>
          <Typography variant="body1" color="#667085">
            You can change your organization name here.
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Formik
            initialValues={{
              company_name: data?.myCompany?.name || "",
            }}
            validationSchema={Yup.object().shape({
              company_name: Yup.string().label("Organization name").required(),
            })}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values) => {
              await editMyCompany({
                variables: {
                  data: {
                    name: values.company_name,
                    employeeCount: data?.myCompany?.employeeCount,
                  },
                },
              });
              enqueueSnackbar("Company name has been successfully edited", {
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top",
                },
                autoHideDuration: 3000,
                variant: "success",
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Box sx={{ maxWidth: "640px" }}>
                <form noValidate onSubmit={handleSubmit}>
                  <Box display="flex" columnGap="16px">
                    <Box flexGrow={1}>
                      <TextField
                        margin="none"
                        error={Boolean(
                          touched.company_name && errors.company_name
                        )}
                        helperText={touched.company_name && errors.company_name}
                        name="company_name"
                        placeholder="Organization name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.company_name}
                        id="company_name"
                        sx={{
                          width: "100%",
                          maxWidth: "300px",
                          marginBottom: "16px",
                        }}
                        inputProps={{
                          style: {
                            padding: "12px 16px",
                          },
                        }}
                      />
                    </Box>
                    <Box textAlign="center">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={
                          isSubmitting ||
                          values.company_name === data?.myCompany?.name
                        }
                        sx={{
                          width: "120px",
                          color: "#ffffff",
                          backgroundColor: "#4C7DE7",
                          "&:hover": {
                            backgroundColor: "#3d6dd4",
                          },
                        }}
                      >
                        Rename
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "16px", marginBottom: "38px" }} />
      <Grid
        container
        columnSpacing="64px"
        rowSpacing="24px"
        marginBottom="32px"
      >
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="body1" fontWeight="500" color="#344054">
            Team members
          </Typography>
          <Typography variant="body1" color="#667085">
            Manage your existing team members
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Box
            display="flex"
            justifyContent="end"
            maxWidth="640px"
            marginBottom="37px"
          >
            <Button
              onClick={() => setInvitePopupOpen(true)}
              variant="contained"
              sx={{
                color: "#ffffff",
                backgroundColor: "#4C7DE7",
                "&:hover": {
                  backgroundColor: "#3d6dd4",
                },
              }}
            >
              Invite new member
            </Button>
          </Box>
          <Box
            sx={{
              maxWidth: "640px",
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            <Grid
              container
              sx={{
                alignItems: "center",
                height: "55px",
                minWidth: "640px",
                backgroundColor: "#f0f0f0",
                border: "1px solid rgba(80, 80, 80, 0.1)",
              }}
            >
              <Grid item xs={3} align="left">
                <Typography paddingX="24px">Name</Typography>
              </Grid>
              <Grid item xs={5} align="left">
                <Typography paddingX="24px">E-Mail</Typography>
              </Grid>
              <Grid item xs={2} align="left">
                <Typography paddingX="24px">Status</Typography>
              </Grid>
              <Grid item xs={2} align="left">
                <Typography paddingX="24px">Action</Typography>
              </Grid>
            </Grid>
            {data.myCompany?.users.map((u) => (
              <Grid
                container
                sx={{
                  alignItems: "center",
                  minWidth: "640px",
                  borderBottom: "1px solid #EAECF0",
                  minHeight: "75px",
                }}
              >
                <Grid item xs={3} align="left">
                  <Typography paddingX="24px">
                    {[u.firstName, u.lastName].filter(Boolean).join(" ") || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={5} align="left">
                  <Typography paddingX="24px">{u.email || "-"}</Typography>
                </Grid>
                <Grid item xs={2} align="left">
                  <Typography paddingX="24px">
                    {u.isInvited ? "Pending" : "Accepted"}
                  </Typography>
                </Grid>
                <Grid item xs={2} align="left" paddingLeft="12px">
                  {data.myCompany?.admin.id === myProfile.id &&
                    u.id !== myProfile.id && (
                      <Button
                        variant="text"
                        color="inherit"
                        onClick={() => {
                          setSelectedMemberID(u.id);
                          setDeletePopupOpen(true);
                        }}
                      >
                        <Typography color="#DB504A">Delete</Typography>
                      </Button>
                    )}
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>
      <InviteMemberPopup open={invitePopupOpen} setOpen={setInvitePopupOpen} />
      <DeleteMemberPopup
        open={deletePopupOpen}
        setOpen={setDeletePopupOpen}
        selectedMemberID={selectedMemberID}
        setSelectedMemberID={setSelectedMemberID}
      />
    </Box>
  );
}

export default TeamsTabPanel;

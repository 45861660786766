import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import { DELETE_PROJECT } from "../../graphql/mutation/project";
import { useApolloClient, useMutation } from "@apollo/client";

export default function DeleteProject({
  handleClosePopup,
  open,
  setOpen,
  setSelectedMenu,
  projectId,
  pName,
}) {
  const client = useApolloClient();

  const handleClose = () => {
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };

  const [deleteProject] = useMutation(DELETE_PROJECT);

  const handleDeleteClose = async () => {
    await deleteProject({
      variables: {
        id: projectId,
      },
    }).then(() => {
      // delete the project from the cache
      client.cache.modify({
        fields: {
          myProjects(existingProjects = [], { readField }) {
            return existingProjects.filter(
              (projectRef) => projectId !== readField("id", projectRef)
            );
          },
        },
      });
    });
    setSelectedMenu("");
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">
            Really want to delete project {pName}?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="popUpText">
            Since it is active, deleting will permanently remove all commands as
            well and it won’t be visible in your application.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                autoFocus
                onClick={handleClose}
                variant="outlined"
                fullWidth
                sx={{
                  color: "rgba(21, 25, 32, 0.5)",
                  border: "1px solid rgba(86, 103, 137, 0.26)",
                  borderRadius: "8px",
                  "&:hover": {
                    borderColor: "rgba(21, 25, 32, 0.5)",
                  },
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                onClick={handleDeleteClose}
                variant="contained"
                fullWidth
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#DB504A",
                  "&:hover": {
                    backgroundColor: "#C43F39",
                  },
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

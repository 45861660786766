import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import TabList from "./TabList";
import { MY_PROJECTS } from "../../graphql/query/project";
import { useQuery } from "@apollo/client";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabView() {
  const [value, setValue] = React.useState(0);
  const { loading, error, data } = useQuery(MY_PROJECTS);

  if (loading || error) return null;

  const myProjects = data?.myProjects;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Current projects" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Grid
        container
        item
        alignItems="center"
        sx={{
          height: "55px",
          marginTop: "14px",
          marginBottom: "8px",
          backgroundColor: "#f0f0f0",
          border: "1px solid rgba(80, 80, 80, 0.1)",
        }}
      >
        <Grid item xs={3} align="center">
          <Typography>Project Name</Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <Typography>Status </Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <Typography># of commands </Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <Typography>Action </Typography>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        {myProjects?.map((project) => (
          <TabList key={project.id} project={project} />
        ))}
      </TabPanel>
    </Box>
  );
}

import React from "react";
import { OnboardingContext } from "../../context/OnboardingProvider";
import { Step1, Step2, Step3, Step4, Step5, Step6 } from "./Steps/index";

function OnboardingStepper() {
  const { activeStep } = React.useContext(OnboardingContext);

  if (activeStep === 0) {
    return <Step1 />;
  }

  if (activeStep === 1) {
    return <Step2 />;
  }

  if (activeStep === 2) {
    return <Step3 />;
  }

  if (activeStep === 3) {
    return <Step4 />;
  }

  if (activeStep === 4) {
    return <Step5 />;
  }

  if (activeStep === 5) {
    return <Step6 />;
  }

  return null;
}

export default OnboardingStepper;

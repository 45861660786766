import { gql } from "@apollo/client";

export const DELETE_INVITE = gql`
  mutation deleteInvite($id: ID!) {
    deleteInvite(id: $id) {
      id
    }
  }
`;

export const INVITE_MEMBERS = gql`
  mutation inviteMembers($emails: [String!]!) {
    inviteMembers(emails: $emails) {
      id
      email
      isInvited
    }
  }
`;
export const CREATE_COMPANY = gql`
  mutation createCompany($data: CompanyCreateInput!) {
    createCompany(data: $data) {
      id
      name
      employeeCount
    }
  }
`;

export const EDIT_COMPANY = gql`
  mutation editMyCompany($data: CompanyEditInput!) {
    editMyCompany(data: $data) {
      id
      name
      employeeCount
      adminId
      admin {
        id
      }
    }
  }
`;

import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { RawCheckIcon } from "../../assets/icons";

function PlansTabPanel() {
  return (
    <Box paddingTop="56px">
      <Grid container spacing="64px">
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h1" fontWeight={500} color="#1A1919">
              Starter
            </Typography>
            <Typography variant="body1" color="#666666" marginY="8px">
              All the tools you need to implement Magny and delight your users.
            </Typography>
            <Box marginTop="16px" display="flex" alignItems="center" gap="8px">
              <Box>
                <Typography
                  variant="h2"
                  fontWeight={500}
                  color="#1A1919"
                  component="span"
                >
                  $
                </Typography>
                <Typography
                  variant="h1"
                  fontSize="36px"
                  color="#1A1919"
                  component="span"
                >
                  0
                </Typography>
                <Typography
                  variant="body1"
                  color="#666666"
                  component="span"
                  marginLeft={1}
                >
                  per month
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "0px 4px",
                  border: "1px solid #DEDEDE",
                  borderRadius: "6px",
                }}
              >
                <Typography variant="body1" color="#4D4D4D">
                  Free
                </Typography>
              </Box>
            </Box>
            <Button
              disabled
              variant="contained"
              sx={{
                fontWeight: 500,
                width: "120px",
                marginTop: "20px",
                color: "#ffffff",
                backgroundColor: "#4C7DE7",
                "&:hover": {
                  backgroundColor: "#3d6dd4",
                },
              }}
            >
              Current Plan
            </Button>
            <Typography
              variant="body1"
              color="#1A1919"
              marginTop="24px"
              marginBottom="8px"
            >
              Includes
            </Typography>
            <Box display="flex" flexDirection="column" gap="8px">
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Unlimited users
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Unlimited projects
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Recommendations
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Fuzzy search
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  User feedbacks
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Basic analytics
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Live chat support
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: "relative",
              border: "1px solid rgba(80, 80, 80, 0.1)",
              borderRadius: "2px",
              backgroundColor: "#f6f8ff",
              padding: "14px 29px 32px 29px",
            }}
          >
            <Box
              position="absolute"
              top="0"
              right="0"
              sx={{
                borderLeft: "96px solid transparent",
                borderRight: "96px solid #CD1313",
                borderBottom: "96px solid transparent",
                height: 0,
                width: 0,
              }}
            >
              <Typography
                variant="body1"
                fontSize="12px"
                color="#ffffff"
                sx={{
                  position: "absolute",
                  width: "68px",
                  top: "26px",
                  left: "34px",
                  transform: "rotate(45deg)",
                }}
              >
                Best Value!
              </Typography>
            </Box>
            <Typography variant="h1" fontWeight={500} color="#1A1919">
              Pro
            </Typography>
            <Typography variant="body1" color="#666666" marginY="8px">
              For organizations that need additional analytics, integrations,
              control and support.
            </Typography>
            <Box marginTop="16px" display="flex" alignItems="center" gap="8px">
              <Box>
                <Typography
                  variant="h2"
                  fontWeight={500}
                  color="#1A1919"
                  component="span"
                >
                  $
                </Typography>
                <Typography
                  variant="h1"
                  fontSize="36px"
                  color="#1A1919"
                  component="span"
                >
                  179
                </Typography>
                <Typography
                  variant="body1"
                  color="#666666"
                  component="span"
                  marginLeft={1}
                >
                  per month
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{
                width: 218,
                fontWeight: 500,
                marginTop: "20px",
                color: "#ffffff",
                backgroundColor: "#4C7DE7",
                "&:hover": {
                  backgroundColor: "#3d6dd4",
                },
              }}
            >
              Buy now
            </Button>
            <Typography
              variant="body1"
              color="#1A1919"
              marginTop="24px"
              marginBottom="8px"
            >
              Includes
            </Typography>
            <Box display="flex" flexDirection="column" gap="8px">
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Everything in Starter plan
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Advanced analytics
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Advanced UI customizations
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Premium support
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Service level agreement
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Live chat support
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  Data import/export
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <RawCheckIcon />
                <Typography variant="body1" color="#666666">
                  API
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#EEF3FF",
                    padding: "0px 4px",
                    border: "1px solid #DEDEDE",
                    borderRadius: "6px",
                  }}
                >
                  <Typography variant="body1" color="#3069FE">
                    Beta
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="end" marginY="24px">
        <Button
          variant="contained"
          sx={{
            width: "218px",
            color: "#ffffff",
            backgroundColor: "#4C7DE7",
            "&:hover": {
              backgroundColor: "#3d6dd4",
            },
          }}
        >
          View invoices
        </Button>
      </Box>
    </Box>
  );
}

export default PlansTabPanel;

import React from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "./Signup";

const SignupView = () => {
  const navigate = useNavigate();
  const handleOnSuccess = () => {
    navigate("/home");
  };
  return <SignUp onSubmitSuccess={handleOnSuccess} />;
};

export default SignupView;

import { gql } from "@apollo/client";

export const CREATE_COMMAND = gql`
  mutation CreateCommand($projectId: ID!, $data: CommandCreateInput!) {
    createCommand(projectId: $projectId, data: $data) {
      id
      title
      description
      icon
      hasIcon
      alwaysShow
      isRecommended
      shortcut
      keywords
      actionType
      status
      callback
      link
      openInSameTab
      webhook
      useCount
    }
  }
`;

export const MOVE_COMMAND = gql`
  mutation moveCommand($commandId: ID!, $categoryId: ID) {
    moveCommand(commandId: $commandId, categoryId: $categoryId) {
      id
    }
  }
`;

export const DELETE_COMMAND = gql`
  mutation deleteCommand($deleteCommandId: ID!) {
    deleteCommand(id: $deleteCommandId) {
      id
    }
  }
`;

export const EDIT_COMMAND = gql`
  mutation editCommand($editCommandId: ID!, $data: CommandEditInput!) {
    editCommand(id: $editCommandId, data: $data) {
      id
      shortcut
      title
      description
      icon
      hasIcon
      alwaysShow
      isRecommended
      keywords
      actionType
      status
      callback
      link
      openInSameTab
      webhook
      useCount
    }
  }
`;

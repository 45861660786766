import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteProject from "../ProjectsPopUps/DeleteProjectPopup";
import DraftProject from "../ProjectsPopUps/DraftProjectPopup";
import ActivateProject from "../ProjectsPopUps/ActivateProjectPopup";
import RenameProject from "../ProjectsPopUps/RenameProjectPopup";
import { Link } from "react-router-dom";

const ITEM_HEIGHT = 48;

export default function TabListMenu({ projectId, projectName, projectStatus }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState("");
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (projectStatus === "ACTIVE") {
      setOptions(["View & Edit", "Delete", "Make draft", "Rename"]);
    }

    if (projectStatus === "DRAFT") {
      setOptions(["View & Edit", "Delete", "Make active", "Rename"]);
    }
  }, [projectStatus]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const popupOpener = (option) => {
    setSelectedMenu(option);
    setPopupOpen(true);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            component={option === "View & Edit" ? Link : MenuItem}
            to={option === "View & Edit" ? `${projectId}` : ""}
            onClick={() => popupOpener(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {selectedMenu === "Delete" && (
        <DeleteProject
          handleClosePopup={handleClose}
          open={popupOpen}
          setSelectedMenu={setSelectedMenu}
          setOpen={setPopupOpen}
          projectId={projectId}
          pName={projectName}
        />
      )}
      {selectedMenu === "Make draft" && (
        <DraftProject
          handleClosePopup={handleClose}
          open={popupOpen}
          setSelectedMenu={setSelectedMenu}
          setOpen={setPopupOpen}
          pName={projectName}
          pId={projectId}
        />
      )}

      {selectedMenu === "Make active" && (
        <ActivateProject
          handleClosePopup={handleClose}
          open={popupOpen}
          setSelectedMenu={setSelectedMenu}
          setOpen={setPopupOpen}
          pName={projectName}
          pId={projectId}
        />
      )}
      {selectedMenu === "Rename" && (
        <RenameProject
          handleClosePopup={handleClose}
          open={popupOpen}
          setSelectedMenu={setSelectedMenu}
          setOpen={setPopupOpen}
          pName={projectName}
          pId={projectId}
        />
      )}
    </div>
  );
}

import React from "react";
import { Box } from "@mui/material";
import OnboardingProvider from "../context/OnboardingProvider";
import Page from "../components/Page";
import Logo from "../components/Logo";
import OnboardingStepper from "../components/Onboarding/OnboardingStepper";
import OnboardingBG from "../assets/images/onboarding-bg.png";

function Onboarding() {
  return (
    <OnboardingProvider>
      <Page title="Onboarding">
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          minHeight="100vh"
        >
          <Box
            display="flex"
            justifyContent="center"
            sx={{ backgroundColor: "rgba(238, 240, 244, .6)" }}
          >
            <Logo />
          </Box>
          <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            justifyContent="center"
            backgroundColor="#f9f9f9"
          >
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              sx={{ marginTop: "64px", marginBottom: "144px" }}
            >
              <OnboardingStepper />
            </Box>
          </Box>
          <Box
            display="flex"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            height="100%"
            maxHeight="127px"
            sx={{
              backgroundImage: `url(${OnboardingBG})`,
              backgroundRepeatY: "no-repeat",
              backgroundPosition: "bottom",
            }}
          />
        </Box>
      </Page>
    </OnboardingProvider>
  );
}

export default Onboarding;

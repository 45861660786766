import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { OnboardingContext } from "../../../context/OnboardingProvider";
import OnboardingCard from "../OnboardingCard";
import OnboardingButton from "../OnboardingButton";

function Step2() {
  const { setActiveStep, companyName, setCompanyName } =
    React.useContext(OnboardingContext);

  return (
    <OnboardingCard sx={{ padding: "24px 39px 40px" }}>
      <Typography
        variant="h2"
        fontSize="18px"
        color="#303030"
        marginBottom="12px"
      >
        About your organization
      </Typography>
      <Typography variant="body1" color="#667080">
        What is your organization's name? This will be used when you form a
        team.
      </Typography>
      <TextField
        fullWidth
        size="small"
        onChange={(e) => {
          setCompanyName(e.target.value);
        }}
        sx={{
          fontSize: "14px",
          marginY: "22px",
          input: { color: "#000000", fontWeight: "500" },
        }}
      />
      <Box textAlign="center">
        <OnboardingButton
          disabled={!companyName.trim()}
          handleClick={() => setActiveStep(2)}
        >
          Next
        </OnboardingButton>
      </Box>
    </OnboardingCard>
  );
}

export default Step2;

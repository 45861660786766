import React from "react";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { CREATE_PROJECT } from "../../../graphql/mutation/project";
import { OnboardingContext } from "../../../context/OnboardingProvider";
import { CheckIcon } from "../../../assets/icons";
import OnboardingCard from "../OnboardingCard";
import OnboardingButton from "../OnboardingButton";

function Step5() {
  const { setActiveStep, projectName, setProjectName } =
    React.useContext(OnboardingContext);
  const { enqueueSnackbar } = useSnackbar();
  const [createProject] = useMutation(CREATE_PROJECT);

  const handleCreateProject = async () => {
    await createProject({
      variables: {
        data: { name: projectName, status: "DRAFT" },
      },
      onCompleted: () => {
        setActiveStep(5);
      },
      onError: () => {
        enqueueSnackbar("Something went wrong.", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          autoHideDuration: 3000,
          variant: "error",
        });
      },
    });
  };

  return (
    <OnboardingCard sx={{ padding: "24px 39px 40px" }}>
      <Typography
        variant="h2"
        fontSize="18px"
        color="#303030"
        marginBottom="12px"
      >
        Create your first project
      </Typography>
      <Typography variant="body1" color="#667080">
        Now create your first project (e.g a search window). A project includes
        commands seen when a user opens Magny bar.
      </Typography>
      <TextField
        fullWidth
        size="small"
        onChange={(e) => {
          setProjectName(e.target.value);
        }}
        sx={{
          fontSize: "14px",
          marginTop: "26px",
          marginBottom: "40px",
          input: { color: "#000000", fontWeight: "500" },
        }}
        InputProps={{
          endAdornment: projectName.trim() && (
            <InputAdornment position="end">
              <CheckIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box textAlign="center">
        <OnboardingButton
          disabled={!projectName.trim()}
          handleClick={handleCreateProject}
        >
          Next
        </OnboardingButton>
      </Box>
    </OnboardingCard>
  );
}

export default Step5;

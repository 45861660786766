export const breadcrumbRoutes = (projectID) => {
  return {
    projects: [{ text: "Projects" }],
    projectsDetail: [
      { text: "Projects", path: "/projects" },
      { text: "Edit project" },
    ],
    createCommand: [
      { text: "Projects", path: "/projects" },
      { text: "Edit project", path: `/projects/${projectID}` },
      { text: "Create new command" },
    ],
    editCommand: [
      { text: "Projects", path: "/projects" },
      { text: "Edit project", path: `/projects/${projectID}` },
      { text: "Edit command" },
    ],
    feedbacks: [{ text: "Feedbacks" }],
    analytics: [{ text: "Analytics" }],
    audience: [{ text: "Audience" }],
    settings: [{ text: "Settings" }],
    profile: [{ text: "Profile" }],
  };
};

import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

const ACTION_TYPES = [
  {
    label: "Callback",
    description: "Trigger a function that you define",
    id: "CALLBACK",
  },
  { label: "Link", description: "Open a link", id: "LINK" },
  { label: "Web Hook", description: "Make a webhook action", id: "WEBHOOK" },
];

const ActionsView = ({
  commandValues,
  setCommandValues,
  handleCreateCommand,
  type,
}) => {
  const commandValuesHandler = (event, handleChange) => {
    commandValues[event.target.name] = event.target.value;
    setCommandValues(commandValues);
    handleChange(event);
  };

  return (
    <Formik
      initialValues={{
        actionType: commandValues?.actionType || { label: "" },
        callback: commandValues?.callback || "",
        link: commandValues?.link || "",
        openInSameTab: commandValues?.openInSameTab || false,
        webhook: commandValues?.webhook || "",
      }}
      validationSchema={Yup.object().shape({
        //check if actionType.id is not empty
        actionType: Yup.object().shape({
          id: Yup.string().required("Action type is required"),
        }),
        callback: Yup.string()
          .max(255)
          .when("actionType", {
            is: (actionType) => actionType.id === "CALLBACK",
            then: Yup.string().max(255).required("Callback is required"),
          }),
        link: Yup.string()
          .max(255)
          .when("actionType", {
            is: (actionType) => actionType.id === "LINK",
            then: Yup.string().max(255).required("Link is required"),
          }),
        openInSameTab: Yup.boolean(),
        webhook: Yup.string()
          .max(255)
          .when("actionType", {
            is: (actionType) => actionType.id === "WEBHOOK",
            then: Yup.string().max(255).required("Webhook is required"),
          }),
      })}
      onSubmit={async (values) => {
        handleCreateCommand();
      }}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap="24px">
            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" color="#575F6E">
                  Action type:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Autocomplete
                  id="actionType"
                  name="actionType"
                  options={ACTION_TYPES}
                  getOptionLabel={(option) => option.label}
                  value={values.actionType}
                  onChange={(e, value) => {
                    setFieldValue("actionType", value || { label: "" });
                    commandValues["actionType"] = value;
                    setCommandValues(commandValues);
                  }}
                  renderOption={(props, option) => (
                    <Box display="flex" {...props}>
                      <Typography
                        variant="h3"
                        fontSize="14px"
                        marginRight="12px"
                      >
                        {option.label} :
                      </Typography>
                      <Typography>{option.description}</Typography>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        touched.actionType && errors.actionType?.id
                      )}
                      helperText={touched.actionType && errors.actionType?.id}
                      variant="outlined"
                      label="Select action type"
                      size="medium"
                    />
                  )}
                />
              </Grid>
            </Grid>
            {values.actionType?.id === "CALLBACK" && (
              <Grid container display="flex" alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" color="#575F6E">
                    Callback:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    error={Boolean(touched.callback && errors.callback)}
                    helperText={touched.callback && errors.callback}
                    id="callback"
                    name="callback"
                    onChange={(event) =>
                      commandValuesHandler(event, handleChange)
                    }
                    onBlur={handleBlur}
                    type="text"
                    value={values.callback}
                    fullWidth
                    variant="outlined"
                    placeholder="function"
                    inputProps={{
                      style: {
                        padding: "6px 16px",
                        height: "40px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {values.actionType?.id === "LINK" && (
              <Grid container display="flex" alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" color="#575F6E">
                    Link:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    error={Boolean(touched.link && errors.link)}
                    helperText={touched.link && errors.link}
                    id="link"
                    name="link"
                    onChange={(event) =>
                      commandValuesHandler(event, handleChange)
                    }
                    onBlur={handleBlur}
                    type="text"
                    value={values.link}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter link"
                    inputProps={{
                      style: {
                        padding: "6px 16px",
                        height: "40px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={5}>
                  <FormControlLabel
                    id="openInSameTab"
                    name="openInSameTab"
                    onBlur={handleBlur}
                    type="checkbox"
                    onChange={(event, value) => {
                      commandValues["openInSameTab"] = value;
                      setCommandValues(commandValues);
                      handleChange(event);
                    }}
                    checked={values.openInSameTab || false}
                    control={<Checkbox />}
                    color="#575F6E"
                    label={
                      <Typography variant="body1" color="#575F6E">
                        Open link in the same tab
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            )}
            {values.actionType?.id === "WEBHOOK" && (
              <Grid container display="flex" alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" color="#575F6E">
                    Webhook:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    error={Boolean(touched.webhook && errors.webhook)}
                    helperText={touched.webhook && errors.webhook}
                    id="webhook"
                    name="webhook"
                    onChange={(event) =>
                      commandValuesHandler(event, handleChange)
                    }
                    onBlur={handleBlur}
                    type="text"
                    value={values.webhook}
                    fullWidth
                    variant="outlined"
                    placeholder="https://"
                    inputProps={{
                      style: {
                        padding: "6px 16px",
                        height: "40px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={8}
              marginTop="32px"
              display="flex"
              justifyContent="end"
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                sx={{ width: "92px" }}
              >
                {type === "edit" ? "Edit!" : "Create!"}
              </Button>
            </Grid>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ActionsView;

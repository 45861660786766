import { Divider, Grid, Box, Typography } from "@mui/material";

export default function DeadendsTabList({ keyword, value }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container item alignItems="center" sx={{ paddingY: "10px" }}>
        <Grid item xs={6} align="center">
          <Typography align="left" paddingX={2}>
            {keyword}
          </Typography>
        </Grid>
        <Grid item xs={6} align="center">
          <Typography align="left" paddingX={2}>
            {value}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
}

import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import CreateShortcutPopup from "../CommandPopups/CreateShortcutPopup";

const IconsShortcutsView = ({
  commandValues,
  setCommandValues,
  handleCreateCommand,
  type,
}) => {
  const [shortcutOpen, setShortcutOpen] = React.useState(false);
  const [hotkeyArr, setHotkeyArr] = React.useState([]);

  return (
    <>
      <Formik
        initialValues={{
          icon: "",
          hasIcon: commandValues?.hasIcon || false,
          shortcut: commandValues?.shortcut || "",
        }}
        validationSchema={Yup.object().shape({
          hasIcon: Yup.boolean(),
        })}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values) => {
          handleCreateCommand();
        }}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap="24px">
              <Grid container display="flex" alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" color="#575F6E">
                    Icon (optional):
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5} display="flex" alignItems="center">
                  <Box
                    textAlign="center"
                    width="34px"
                    height="28px"
                    border="1px solid rgba(0, 0, 0, 0.7)"
                    borderRadius="8px"
                    marginRight="24px"
                  >
                    :)
                  </Box>
                  <FormControlLabel
                    id="hasIcon"
                    name="hasIcon"
                    onChange={(event, value) => {
                      commandValues["hasIcon"] = value;
                      setCommandValues(commandValues);
                      handleChange(event);
                    }}
                    checked={values.hasIcon || false}
                    type="checkbox"
                    onBlur={handleBlur}
                    control={<Checkbox />}
                    color="#575F6E"
                    label={
                      <Typography variant="body1" color="#575F6E">
                        Do not use an icon
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container display="flex" alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" color="#575F6E">
                    Shortcut (optional):
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    error={Boolean(touched.shortcut && errors.shortcut)}
                    helperText={touched.shortcut && errors.shortcut}
                    id="shortcut"
                    name="shortcut"
                    disabled
                    onBlur={handleBlur}
                    value={commandValues?.shortcut?.join(" + ") || ""}
                    type="text"
                    placeholder="e.g. ctrl + shift + a"
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: "6px 16px",
                        height: "40px",
                      },
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShortcutOpen(true)}
                  >
                    Add Shortcut
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                display="flex"
                justifyContent="end"
                marginTop="32px"
              >
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  sx={{ width: "92px" }}
                >
                  {type === "edit" ? "Edit!" : "Create!"}
                </Button>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
      {shortcutOpen && (
        <CreateShortcutPopup
          setCommandValues={setCommandValues}
          commandValues={commandValues}
          open={shortcutOpen}
          setOpen={setShortcutOpen}
          setHotkeyArr={setHotkeyArr}
          hotkeyArr={hotkeyArr}
        />
      )}
    </>
  );
};

export default IconsShortcutsView;

import React from "react";
import { Link } from "react-router-dom";
import { AppBarContext } from "../context/AppBarProvider";
import { Container, Grid, Typography, Box } from "@mui/material";
import styled from "@mui/material/styles/styled";
import Page from "../components/Page";
import { UserContext } from "../context/UserProvider";
import QuickAccess from "../components/QuickAccess";
import StatisticCard from "../components/Charts/StatisticCard";

const HeaderBox = styled(Box)(({ theme }) => ({
  padding: "10px 0px",
}));

function Header({ title, description }) {
  return (
    <HeaderBox>
      <Typography variant="h2" color="#1E293B">
        {title}
      </Typography>
      <Typography variant="body1" color="#475569">
        {description}
      </Typography>
    </HeaderBox>
  );
}

function FeatureLink({ title, date, link }) {
  return (
    <Box>
      <Typography variant="h5" color="#4C7DE7">
        {date}
      </Typography>
      <Box display="flex" alignItems="center" gap="10px">
        <Typography variant="body2" color="#1E293B">
          {title}
        </Typography>
        <Link to={link}>
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.50002 1.6065C4.50002 2.08496 4.87502 2.46089 5.33336 2.46089H9.99169L0.91669 11.7652C0.760875 11.925 0.67334 12.1416 0.67334 12.3676C0.67334 12.5935 0.760875 12.8102 0.91669 12.9699C1.0725 13.1297 1.28384 13.2194 1.50419 13.2194C1.72455 13.2194 1.93588 13.1297 2.09169 12.9699L11.1667 3.66558V8.44164C11.1667 8.91155 11.5417 9.29603 12 9.29603C12.4584 9.29603 12.8334 8.91155 12.8334 8.44164V1.6065C12.8334 1.13658 12.4584 0.752106 12 0.752106H5.33336C4.87502 0.752106 4.50002 1.13658 4.50002 1.6065Z"
              fill="#4C7DE7"
            />
          </svg>
        </Link>
      </Box>
    </Box>
  );
}

const Dashboard = () => {
  const { setText, setTitle } = React.useContext(AppBarContext);
  const { myProfile } = React.useContext(UserContext);

  React.useEffect(() => {
    setTitle("");
    setText("You are on a free forever plan.");
  }, [setText, setTitle]);

  return (
    <Page title="Home">
      <Container sx={{ marginY: { xs: "8px", md: "32px" } }}>
        <Header
          title={`Hello, ${myProfile?.firstName} 👋`}
          description="All summaries and activities"
        />
        <Grid
          container
          columnSpacing="34px"
          sx={{ mt: "28px" }}
        >
          <Grid item xs={12}>
            <Typography variant="h1" marginBottom="12px">Analytics</Typography>
          </Grid>
          <Grid item md={6} xs={12} marginBottom="24px">
            <StatisticCard />
          </Grid>
          <Grid item md={6} xs={12} marginBottom="24px">
            <StatisticCard />
          </Grid>
          <Grid item md={6} xs={12} marginBottom="24px">
            <QuickAccess />
          </Grid>
          <Grid item md={6} xs={12} marginBottom="24px">
            <Typography variant="h1" marginBottom="12px">
              Latest News
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                border: "1px solid rgba(80, 80, 80, 0.1)",
                borderRadius: "2px",
                p: "14px",
                background: "#fff",
              }}
              gap="12px"
            >
              <FeatureLink
                title="New features in Magny 4.2"
                date="WED 29 DEC"
                link="/home"
              />
              <FeatureLink
                title="Tips &amp; tricks building your search bar with Magny"
                date="THU 9 OCT"
                link="/home"
              />
              <FeatureLink
                title="User onboarding: increase the funnel rates by 30%"
                date="FRI 9 NOV"
                link="/home"
              />
              <FeatureLink
                title="New features in Magny 4.2"
                date="WED 29 DEC"
                link="/home"
              />
              <Box display="flex" justifyContent="end" marginRight="16px">
                <Link to="/home" style={{ textDecoration: "none" }}>
                  <Typography variant="body1" color="#4C7DE7">
                    <span style={{ textDecoration: "underline" }}>
                      Check latest news
                    </span>{" "}
                    &#8594;
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;

import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography, Tab, Tabs } from "@mui/material";
import TabList from "./TabList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabView({ feedbacks }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Feedbacks" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Grid
        container
        item
        alignItems="center"
        sx={{
          height: "55px",
          marginTop: "14px",
          marginBottom: "8px",
          backgroundColor: "#f0f0f0",
          border: "1px solid rgba(80, 80, 80, 0.1)",
        }}
      >
        <Grid item xs={4} align="center">
          <Typography>Feedback</Typography>
        </Grid>
        <Grid item xs={4} align="center">
          <Typography>Date</Typography>
        </Grid>
        <Grid item xs={4} align="center">
          <Typography>Project</Typography>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        {feedbacks.map((feedback) => (
          <TabList
            key={feedback.id}
            message={feedback.message}
            createdAt={feedback.createdAt}
            projectName={feedback.projectName}
          />
        ))}
      </TabPanel>
    </Box>
  );
}

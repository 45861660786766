import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

function Logo({ setMobileOpen }) {
  return (
    <Box
      component={Link}
      to="/home"
      onClick={() => setMobileOpen(false)}
      sx={{ padding: 2 }}
    >
      <img alt="Logo" src="/MagnyLogo.svg" />
    </Box>
  );
}
export default Logo;

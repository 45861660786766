import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { UserContext } from "../context/UserProvider";
import { AppBarContext } from "../context/AppBarProvider";
import Page from "../components/Page";
import Header from "../components/Header";
import SearchKeywordTabView from "../components/AnalyticsTabView/SearchKeywordTabView";
import DeadendsTabView from "../components/AnalyticsTabView/DeadendsTabView";
import StatisticCard from "../components/Charts/StatisticCard";
import { breadcrumbRoutes } from "../components/Breadcrumb/breadcrumbRoutes";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

function AnalyticsBanner({ myProfile }) {
  return (
    <>
      {!myProfile?.hasSeenBanners?.analytics && (
        <Header
          title="Get more insights from your app 😋"
          description="See what your users search for in your application. Get great insights about what your users are doing in your application and improve your application's features for a better onboarding."
          pageName="analytics"
        />
      )}
    </>
  );
}

const Analytics = () => {
  const { setTitle, setText } = React.useContext(AppBarContext);
  const { myProfile } = React.useContext(UserContext);
  const isPremium = false;
  React.useEffect(() => {
    setText("");
    setTitle("Analytics");
  }, [setTitle, setText]);

  return (
    <Page title="Analytics">
      <Container>
        <Breadcrumb breadcrumbRoutes={breadcrumbRoutes().analytics} />
        <AnalyticsBanner myProfile={myProfile} />
        <Grid container spacing="48px">
          <Grid item md={6} xs={12}>
            <StatisticCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <StatisticCard />
          </Grid>
        </Grid>
        <Box marginY="42px" />
        <Box position="relative">
          <Grid container spacing="48px">
            <Grid item md={6} xs={12}>
              <SearchKeywordTabView />
            </Grid>
            <Grid item md={6} xs={12}>
              <DeadendsTabView />
            </Grid>
          </Grid>
          {!isPremium && (
            <Box
              position="absolute"
              display="flex"
              justifyContent="center"
              alignItems="center"
              top={{ xs: "48px", md: "111px" }}
              left="0"
              right="0"
              height={{ xs: "calc(100% - 48px)", md: "calc(100% - 111px)" }}
              sx={{
                backgroundColor: "rgba(213, 213, 213, 0.48)",
                boxShadow: "0 0 20px 14px rgba(213, 213, 213, 0.48)",
              }}
            >
              <Typography variant="h2" color="#4C7DE7">
                Available in Pro plan
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
};
export default Analytics;

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BreadcrumbHomeIcon } from "../../assets/icons";
import { Box, IconButton, Typography, Link as MUILink } from "@mui/material";
import { Link } from "react-router-dom";

const Seperator = () => {
  return (
    <Typography fontSize="14px" fontWeight="600" color="#D0D5DD">
      /
    </Typography>
  );
};

function Breadcrumb({ breadcrumbRoutes }) {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      alignItems="center"
      columnGap="14px"
      marginTop="12px"
      marginBottom="40px"
    >
      <IconButton
        color="inherit"
        onClick={() => navigate("/home")}
        sx={{ padding: 0 }}
      >
        <BreadcrumbHomeIcon />
      </IconButton>
      <Seperator />
      {breadcrumbRoutes.map((route, index) => (
        <>
          <MUILink
            component={route.path ? Link : "span"}
            to={route.path || ""}
            key={index}
            underline={route.path ? "hover" : "none"}
            color={route.path ? "#667085" : "#4C7DE7"}
            fontSize="14px"
            fontWeight="500"
          >
            {route.text}
          </MUILink>
          {index !== breadcrumbRoutes.length - 1 && <Seperator />}
        </>
      ))}
    </Box>
  );
}

export default Breadcrumb;

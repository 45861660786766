import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";

const StyledOnboardingButton = styled(Button)(() => ({
  width: "120px",
  maxHeight: "35px",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  fontWeight: 700,
  padding: "8px 12px",
  color: "#ffffff",
  backgroundColor: "#4C7DE7",
  borderRadius: "4px",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#3d6dd4",
  },
}));

function OnboardingButton({ disabled, handleClick, children, ...props }) {
  return (
    <StyledOnboardingButton
      variant="contained"
      disabled={disabled}
      onClick={handleClick}
      {...props}
    >
      {children}
    </StyledOnboardingButton>
  );
}

export default OnboardingButton;

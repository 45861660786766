import React from "react";
import { Box, Typography } from "@mui/material";
import ProjectStatus from "./ProjectStatus";

function ProjectHeader({ name, id, status }) {
  return (
    <Box display="flex" alignItems="center" gap="18px">
      <Box display="flex" alignItems="center" gap="6px">
        <Typography variant="body1" fontSize="12px" color="rgb(53, 53, 53)">
          {name}
        </Typography>
        <Typography variant="body1" fontSize="12px" color="rgb(99, 115, 129)">
          (ID:{id.substring(0, 8).toUpperCase()})
        </Typography>
      </Box>
      <ProjectStatus status={status} />
    </Box>
  );
}

export default ProjectHeader;

import { Card, Metric, Text, AreaChart, BadgeDelta, Flex } from "@tremor/react";
import InfoIcon from "@mui/icons-material/Info";

const data = [
  {
    Month: "Jan 21",
    TotalOpen: 2890,
    Profit: 2400,
    Customers: 4938,
  },
  {
    Month: "Feb 21",
    TotalOpen: 1890,
    Profit: 1398,
    Customers: 2938,
  },
  {
    Month: "Mar 21",
    TotalOpen: 2190,
    Profit: 1900,
    Customers: 1638,
  },
  {
    Month: "Apr 21",
    TotalOpen: 3470,
    Profit: 3908,
    Customers: 2138,
  },
  {
    Month: "May 21",
    TotalOpen: 2170,
    Profit: 4800,
    Customers: 2142,
  },
  {
    Month: "Jun 21",
    TotalOpen: 3170,
    Profit: 3800,
    Customers: 3142,
  },
  {
    Month: "Jul 21",
    TotalOpen: 3490,
    Profit: 4300,
    Customers: 2345,
  },
];

const categories = [
  {
    title: "TotalOpen",
    metric: "12,699",
    metricPrev: "$ 9,456",
    delta: "34.3%",
    deltaType: "moderateIncrease",
  },
];

const valueFormatter = (number) =>
  `$ ${Intl.NumberFormat("us").format(number).toString()}`;

export default function Example() {
  return (
    <>
      {categories.map((item) => (
        <Card key={item.title}>
          <Flex
            alignItems="items-start"
            justifyContent="start"
            spaceX="space-x-20"
          >
            <Text>{item.title}</Text>
            <InfoIcon color="primary" />
          </Flex>
          <Flex
            justifyContent="justify-start"
            alignItems="items-baseline"
            spaceX="space-x-10"
            marginTop="mt-5"
            truncate={true}
          >
            <Metric>{item.metric}</Metric>
            <BadgeDelta deltaType={item.deltaType} text={item.delta} />
          </Flex>
          <AreaChart
            marginTop="mt-3"
            data={data}
            dataKey="Month"
            valueFormatter={valueFormatter}
            categories={[item.title]}
            colors={["blue"]}
            showXAxis={true}
            showGridLines={false}
            startEndOnly={true}
            showYAxis={false}
            showLegend={false}
            height="h-24"
          />
        </Card>
      ))}
    </>
  );
}

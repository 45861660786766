import React from "react";
import { Outlet, useOutlet } from "react-router-dom";
import { Grid, Container, Button } from "@mui/material";
import { AppBarContext } from "../context/AppBarProvider";
import { UserContext } from "../context/UserProvider";
import Page from "../components/Page";
import TabView from "../components/ProjectsTabView/TabView";
import CreateNewProject from "../components/ProjectsPopUps/CreateProjectPopup";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { breadcrumbRoutes } from "../components/Breadcrumb/breadcrumbRoutes";

const Projects = () => {
  const { setTitle, setText } = React.useContext(AppBarContext);
  const { myProfile } = React.useContext(UserContext);
  const [openCreateProjectPopup, setOpenCreateProjectPopup] =
    React.useState(false);
  const outlet = useOutlet();

  React.useEffect(() => {
    if (!outlet) {
      setText("");
      setTitle("Projects");
    }
  }, [outlet, setTitle, setText]);

  if (outlet) {
    return <Outlet />;
  }

  window.CommandBar.addCallback("cmdCreateProject", () =>
    setOpenCreateProjectPopup(true)
  );

  window.CommandBar.addCallback("sayHi", () =>
    alert("Hi " + myProfile?.firstName)
  );

  return (
    <Page title="Projects">
      <Container>
        <Breadcrumb breadcrumbRoutes={breadcrumbRoutes().projects} />
        {!myProfile?.hasSeenBanners?.projects && (
          <Header
            title="Your first project is here 👋"
            description="A projects is a single instance of a Magny popup where you can add as many commands, create nested commands one after another, and install on your app. Now go ahead and click on the project hamburger and edit it."
            pageName="projects"
          />
        )}
        <Grid container sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            onClick={() => setOpenCreateProjectPopup(true)}
          >
            Create Project
          </Button>
        </Grid>
        <Grid container sx={{ mt: "28px" }}>
          <TabView />
        </Grid>
      </Container>
      {openCreateProjectPopup && (
        <CreateNewProject
          open={openCreateProjectPopup}
          setOpen={setOpenCreateProjectPopup}
        />
      )}
    </Page>
  );
};

export default Projects;

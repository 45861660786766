import { SnackbarProvider } from "notistack";
import Route from "./config/Route";
import AppBarProvider from "./context/AppBarProvider";
import UserProvider from "./context/UserProvider";
import "@tremor/react/dist/esm/tremor.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  window.CommandBar.addCallback("throwErr", () => {
    throw new Error("This is an error");
  });

  useEffect(() => {
    const loggedInUserId = JSON.parse(localStorage.getItem("loggedInUser"))?.id;

    if (loggedInUserId) {
      window.CommandBar.boot(loggedInUserId);
    }

    const routerFunc = (newUrl) => navigate(newUrl);
    window.CommandBar.addRouter(routerFunc);
    window.CommandBar.setTheme("benim-theme");

    window.CommandBar.boot(loggedInUserId).then(() => {
      // ...
    });

    return () => {
      window.CommandBar.shutdown();
    };
  }, [navigate]);

  return (
    <SnackbarProvider maxSnack={1}>
      <UserProvider>
        <AppBarProvider>
          <Route />
        </AppBarProvider>
      </UserProvider>
    </SnackbarProvider>
  );
}

export default App;

import { Container, Tabs, Tab, Typography, Box } from "@mui/material";
import { AppBarContext } from "../context/AppBarProvider";
import React from "react";
import Page from "../components/Page";
import PropTypes from "prop-types";
import ProfileTabPanel from "../components/ProfileTabView/ProfileTabPanel";
import PasswordTabPanel from "../components/ProfileTabView/PasswordTabPanel";
import PlansTabPanel from "../components/ProfileTabView/PlansTabPanel";
import { useLocation } from "react-router-dom";
import TeamsTabPanel from "../components/ProfileTabView/TeamsTabPanel";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { breadcrumbRoutes } from "../components/Breadcrumb/breadcrumbRoutes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Profile() {
  const location = useLocation();
  const [value, setValue] = React.useState(location.state?.pNumber || 0);
  const { setText, setTitle } = React.useContext(AppBarContext);

  React.useEffect(() => {
    setTitle("Profile");
    setText("");
  }, [setText, setTitle]);

  React.useEffect(() => {
    setValue(location.state?.pNumber || 0);
  }, [location.state?.pNumber]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Profile">
      <Container>
        <Breadcrumb breadcrumbRoutes={breadcrumbRoutes().profile} />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Password" {...a11yProps(1)} />
              <Tab label="Teams" {...a11yProps(2)} />
              <Tab label="Plans" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ProfileTabPanel />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PasswordTabPanel />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TeamsTabPanel />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PlansTabPanel />
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}

export default Profile;

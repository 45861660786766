import React, { createContext } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { MY_PROFILE } from "../graphql/query/auth";
import authService from "../services/authService";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export const UserContext = createContext();

function UserProvider({ children }) {
  const client = useApolloClient();
  const { loading, data: myProfile } = useQuery(MY_PROFILE);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (loading) return null;
  
  authService.isLoginExpired(client).then((isExpired) => {
    if (isExpired) {
      authService.logout(client);
      navigate("/");
      enqueueSnackbar("Login session expired", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        autoHideDuration: 3000,
        variant: "warning",
      });
    }
  });

  return (
    <UserContext.Provider
      value={{
        myProfile: myProfile?.myProfile,
        loading: loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_COMMAND } from "../../graphql/mutation/command";
import { GET_CATEGORIES_WITH_COMMANDS } from "../../graphql/query/category";
import { useParams } from "react-router-dom";

export default function DeleteCommand({
  handleClosePopup,
  open,
  setOpen,
  setSelectedMenu,
  cName,
  cId,
  categoryId,
}) {
  const client = useApolloClient();
  const { id } = useParams();
  const deleteUpdateCommandCache = () => {
    if (categoryId) {
      const data = client.readQuery({
        query: GET_CATEGORIES_WITH_COMMANDS,
        variables: {
          projectId: id,
        },
      });

      const categories = data?.getCategoriesWithCommands || [];
      const chosenCategory = categories.find((cat) => cat.id === categoryId);

      const commands = chosenCategory.commands.filter((c) => c.id !== cId);

      client.writeQuery({
        query: GET_CATEGORIES_WITH_COMMANDS,
        variables: {
          projectId: id,
        },
        data: {
          getCategoriesWithCommands: [
            ...categories.filter((cat) => cat.id !== categoryId),
            {
              ...chosenCategory,
              commands,
            },
          ],
        },
      });
    } else {
      client.cache.modify({
        fields: {
          getUncategorizedCommands(existingCommands = [], { readField }) {
            return existingCommands.filter(
              (commandRef) => cId !== readField("id", commandRef)
            );
          },
        },
      });
    }
  };
  const [deleteCommand] = useMutation(DELETE_COMMAND);
  const handleClose = () => {
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };
  const handleDeleteCommand = async () => {
    await deleteCommand({ variables: { deleteCommandId: cId } });
    deleteUpdateCommandCache();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">
            Really delete command: {cName}?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="popUpText">
            Since it is active, deleting will permanently remove all commands as
            well and it won't be visible in your application.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                autoFocus
                onClick={handleClose}
                variant="outlined"
                fullWidth
                sx={{
                  color: "rgba(21, 25, 32, 0.5)",
                  border: "1px solid rgba(86, 103, 137, 0.26)",
                  borderRadius: "8px",
                  "&:hover": {
                    borderColor: "rgba(21, 25, 32, 0.5)",
                  },
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                onClick={handleDeleteCommand}
                variant="contained"
                fullWidth
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#DB504A",
                  "&:hover": {
                    backgroundColor: "#C43F39",
                  },
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

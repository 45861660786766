import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

const onKeyPress = (event, setHotkeys, hotkeyArr) => {
  event.preventDefault();
  setHotkeys([...hotkeyArr, event.key]);
};

const useKeyPress = (keys, callback, setHotkeys, hotkeyArr, node = null) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event) => {
      if (keys.some((key) => event.key === key)) {
        callbackRef.current(event, setHotkeys, hotkeyArr);
      }
    },
    [keys, setHotkeys, hotkeyArr]
  );

  useEffect(() => {
    const targetNode = node ?? document;
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};

export const useInitalKeyPress = (setHotkeys, hotkeyArr) => {
  return useKeyPress(
    [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "Control",
      "Shift",
      "Alt",
      "AltGraph",
      "Enter",
    ],
    onKeyPress,
    setHotkeys,
    hotkeyArr
  );
};

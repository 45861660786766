import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation createCategory($data: CategoryCreateInput!) {
    createCategory(data: $data) {
      id
      name
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation editCategory($editCategoryId: ID!, $name: String!) {
    editCategory(id: $editCategoryId, name: $name) {
      name
      id
    }
  }
`;
export const DELETE_CATEGORY = gql`
  mutation deleteCategory($deleteCategoryId: ID!) {
    deleteCategory(id: $deleteCategoryId) {
      id
    }
  }
`;

import React from "react";
import { useNavigate } from "react-router-dom";
import SignIn from "./Login";

const LoginView = () => {
  const navigate = useNavigate();
  const handleOnSuccess = () => {
    navigate("/home");
  };
  return <SignIn onSubmitSuccess={handleOnSuccess} />;
};

export default LoginView;

import React from "react";
import { AppBarContext } from "../context/AppBarProvider";
import Container from "@mui/material/Container";
import Page from "../components/Page";
import { Box, Grid, Typography, Tabs, Tab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import BasicView from "../components/CommandsTabView/BasicsView";
import IconsShortcutsView from "../components/CommandsTabView/IconsShortcutsView";
import ActionsView from "../components/CommandsTabView/ActionsView";
import { useApolloClient, useMutation } from "@apollo/client";
import { CREATE_COMMAND, MOVE_COMMAND } from "../graphql/mutation/command";
import { createUpdateCommandCache } from "../graphql/helpers";
import { useSnackbar } from "notistack";
import { breadcrumbRoutes } from "../components/Breadcrumb/breadcrumbRoutes";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateCommand = () => {
  const navigate = useNavigate();

  const client = useApolloClient();
  const { id } = useParams();

  const { setTitle, setText } = React.useContext(AppBarContext);
  const [value, setValue] = React.useState(0);
  const [commandValues, setCommandValues] = React.useState({});

  const [createCommand] = useMutation(CREATE_COMMAND);
  const [moveCommand] = useMutation(MOVE_COMMAND);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setText("");
    setTitle("Create new command");
  }, [setTitle, setText]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreateCommand = async () => {
    let errMsg = false;
    if (!commandValues.actionType) {
      setValue(2);
      errMsg = "Action Type";
    } else if (!commandValues.title) {
      setValue(0);
      errMsg = "Title";
    }

    if (errMsg) {
      enqueueSnackbar(`${errMsg} is Required`, {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        autoHideDuration: 3000,
        variant: "error",
      });
      return;
    }

    const customVals = {
      ...commandValues,
      actionType: commandValues.actionType?.id || null,
      alwaysShow: commandValues.alwaysShow || false,
      isRecommended: commandValues.isRecommended || false,
      hasIcon: commandValues.hasIcon || false,
      shortcut: commandValues.shortcut || [""],
      keywords: commandValues.keywords || [""],
    };

    delete customVals.category;

    const { data } = await createCommand({
      variables: {
        projectId: id,
        data: customVals,
      },
    });

    await moveCommand({
      variables: {
        commandId: data.createCommand.id,
        categoryId: commandValues.category?.id || null,
      },
    });

    createUpdateCommandCache(client, id, {
      newCommand: data.createCommand,
      categoryId: commandValues.category?.id || null,
    });

    enqueueSnackbar("New Command is Created", {
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      autoHideDuration: 3000,
      variant: "success",
    });

    navigate("/projects/" + id);
  };

  return (
    <Page title="Create Command">
      <Container>
        <Breadcrumb breadcrumbRoutes={breadcrumbRoutes(id).createCommand} />
        <Typography variant="body1" color="#667080" marginBottom="32px">
          Add a command for this project. You can add as many commands as you
          want.
        </Typography>
        <Grid container item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab alignItems="start" label="Basics" {...a11yProps(0)} />
                <Tab label="Icons & shortcuts" {...a11yProps(1)} />
                <Tab label="Actions" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <BasicView
                commandValues={commandValues}
                setCommandValues={setCommandValues}
                handleCreateCommand={handleCreateCommand}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <IconsShortcutsView
                commandValues={commandValues}
                setCommandValues={setCommandValues}
                handleCreateCommand={handleCreateCommand}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ActionsView
                commandValues={commandValues}
                setCommandValues={setCommandValues}
                handleCreateCommand={handleCreateCommand}
              />
            </TabPanel>
          </Box>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateCommand;

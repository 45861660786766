import React from "react";
import { Box, Typography } from "@mui/material";
import { OnboardingContext } from "../../../context/OnboardingProvider";
import OnboardingCard from "../OnboardingCard";
import OnboardingButton from "../OnboardingButton";

function Step1() {
  const { setActiveStep } = React.useContext(OnboardingContext);

  return (
    <OnboardingCard sx={{ padding: "24px 39px 40px" }}>
      <Typography
        variant="h2"
        fontSize="18px"
        color="#303030"
        marginBottom="12px"
      >
        Welcome to Magny!
      </Typography>
      <Typography variant="body1" color="#667080">
        Magny helps create great search experiences in your app that delights
        your users as they look for anything.
      </Typography>
      <Box marginTop="58px" textAlign="center">
        <OnboardingButton handleClick={() => setActiveStep(1)}>
          Let's start!
        </OnboardingButton>
      </Box>
    </OnboardingCard>
  );
}

export default Step1;

import React from "react";
import { SearchIcon } from "../assets/icons";
import { InputAdornment, TextField } from "@mui/material";

function Search({ placeholder, ...props }) {
  return (
    <TextField
      size="small"
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        ...props,
        borderRadius: "4px",
        "& .MuiInputBase-input": {
          fontSize: "12px",
          height: "26px",
          padding: "3px 8px",
        },
      }}
    />
  );
}

export default Search;

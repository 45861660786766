import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { CheckIcon } from "../../assets/icons";
import { useMutation } from "@apollo/client";
import { EDIT_CATEGORY } from "../../graphql/mutation/category";

export default function RenameCategory({
  handleClosePopup,
  open,
  setOpen,
  setSelectedMenu,
  cName,
  cId,
}) {
  const re = new RegExp("^.{1,25}$");
  const [categoryName, setCategoryName] = React.useState("");

  const [editCategory] = useMutation(EDIT_CATEGORY);

  const handleClose = () => {
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };
  const handleRenameCategory = async () => {
    await editCategory({
      variables: {
        editCategoryId: cId,
        name: categoryName,
      },
    });
    handleClosePopup();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">
            Rename category: {cName}?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            placeholder={cName}
            sx={{
              paddingTop: "12px",
            }}
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
            InputProps={{
              endAdornment: re.test(categoryName) && (
                <InputAdornment position="end">
                  <CheckIcon />
                </InputAdornment>
              ),
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter" && re.test(categoryName)) {
                ev.preventDefault();
                handleRenameCategory();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleRenameCategory();
                }}
                autoFocus
                disabled={!re.test(categoryName)}
              >
                Rename
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

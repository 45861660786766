import React from "react";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { AppBarContext } from "../context/AppBarProvider";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Appbar from "../components/Appbar";
import Menu from "../components/Menu";

const sidebarWidth = 240;

function Layout() {
  const { mobileOpen, handleDrawerToggle } = React.useContext(AppBarContext);
  const navigate = useNavigate();
  const outlet = useOutlet();
  const location = useLocation();

  React.useEffect(() => {
    if (!outlet) {
      navigate("/home");
    }
  }, [navigate, outlet]);

  return (
    <Box display="flex" width="100vw" height="100vh" overflow="hidden">
      <Box
        flexBasis={sidebarWidth}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Box
          width={sidebarWidth}
          component="header"
          height="100vh"
          display="flex"
          flexDirection="column"
          borderRight="1px solid rgba(167, 176, 189, .6)"
        >
          <Menu activeTab={location.pathname} />
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: sidebarWidth,
            },
          }}
        >
          <Menu activeTab={location.pathname} />
        </Drawer>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow="1"
        overflow="auto"
        sx={{ backgroundColor: "#F9F9F9" }}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            paddingX: { xs: "0", sm: "8px", md: "16px", lg: "32px" },
          }}
        >
          <Appbar />
        </Box>
        <Box
          component="main"
          sx={{
            paddingX: { xs: "0", sm: "8px", md: "16px", lg: "32px" },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;

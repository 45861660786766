import * as React from "react";
import { Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabListMenu from "./TabListMenu";
import { ActiveIcon, DraftIcon } from "../../assets/icons";

function StatusColumn({ status }) {
  if (status === "ACTIVE") {
    return (
      <>
        <ActiveIcon />
        <Typography>Active</Typography>
      </>
    );
  }
  if (status === "DRAFT") {
    return (
      <>
        <DraftIcon />
        <Typography>Draft</Typography>
      </>
    );
  }

  return "-";
}

export default function TabList({ project }) {
  const { id, name, status, commandsCount } = project;

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        item
        alignItems="center"
        sx={{ paddingY: "10px" }}
        key={project.id}
      >
        <Grid item xs={3} align="center">
          <Typography>{name}</Typography>
          <Typography color="#637381">
            ID: {id.substring(0, 8).toUpperCase()}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <StatusColumn status={status} />
        </Grid>
        <Grid item xs={3} align="center">
          <Typography>{commandsCount}</Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <TabListMenu
            projectId={id}
            projectName={name}
            projectStatus={status}
          />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
}

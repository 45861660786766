import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_CATEGORY } from "../../graphql/mutation/category";

export default function DeleteCategory({
  handleClosePopup,
  open,
  setOpen,
  setSelectedMenu,
  cName,
  cId,
}) {
  const client = useApolloClient();
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const handleClose = () => {
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };

  const handleDeleteCategory = async () => {
    await deleteCategory({
      variables: {
        deleteCategoryId: cId,
      },
    }).then(() => {
      // delete the project from the cache
      client.cache.modify({
        fields: {
          getCategoriesWithCommands(existingCategories = [], { readField }) {
            return existingCategories.filter(
              (categoryRef) => cId !== readField("id", categoryRef)
            );
          },
        },
      });
    });
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">
            Really delete category: {cName}?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="popUpText">
            Since it is active, deleting will permanently remove all commands as
            well and it won't be visible in your application.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                autoFocus
                onClick={handleClose}
                variant="outlined"
                fullWidth
                sx={{
                  color: "rgba(21, 25, 32, 0.5)",
                  border: "1px solid rgba(86, 103, 137, 0.26)",
                  borderRadius: "8px",
                  "&:hover": {
                    borderColor: "rgba(21, 25, 32, 0.5)",
                  },
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                onClick={handleDeleteCategory}
                variant="contained"
                fullWidth
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#DB504A",
                  "&:hover": {
                    backgroundColor: "#C43F39",
                  },
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

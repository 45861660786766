import React, { useState } from "react";
import { AppBarContext } from "../context/AppBarProvider";
import {
  Button,
  Grid,
  Box,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Link, Outlet, useOutlet, useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_PROJECT } from "../graphql/query/project";
import {
  GET_CATEGORIES_WITH_COMMANDS,
  GET_UNCATEGORIZED_COMMANDS,
} from "../graphql/query/category";
import Page from "../components/Page";
import DroppableAndDraggable from "../components/DnD/DroppableAndDraggable";
import ProjectHeader from "../components/ProjectDetailView/ProjectHeader";
import AddCategoryPopup from "../components/ProjectDetailPopups/AddCategoryPopup";
import DnDCommandCard from "../components/DnD/DnDCommandCard";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MOVE_COMMAND } from "../graphql/mutation/command";
import { useSnackbar } from "notistack";
import {
  REORDER_CATEGORY,
  REORDER_COMMAND,
} from "../graphql/mutation/ordering";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { breadcrumbRoutes } from "../components/Breadcrumb/breadcrumbRoutes";

function ProjectsHelp() {
  return (
    <Box sx={{ marginTop: "72px" }}>
      <Grid container spacing={{ xs: "72px", lg: "16px" }}>
        <Grid item xs={12} md={6} lg={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="../../feedbacksLeftHero.svg"
              alt="feedbacks"
              style={{ width: "100%", maxWidth: "260px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="feedbacksTitle">
              You don't have any commands or categories, yet
            </Typography>
            <Typography variant="feedbacks">
              A command is a set of actions you want to show your users when
              they open the Magny Bar.
            </Typography>
            <Typography variant="feedbacks">
              Each command can be grouped in a category, however commands may
              have no categories at all.
            </Typography>
            <Typography variant="feedbacks">
              Now head over to top right and either create a command or a
              category to build your Magny Bar experience.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const ProjectDetail = () => {
  const { setTitle, setText } = React.useContext(AppBarContext);
  const outlet = useOutlet();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const { id } = useParams();

  React.useEffect(() => {
    if (!outlet) {
      setText("");
      setTitle("Edit project");
    }
  }, [outlet, setTitle, setText]);

  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [tableUpdating, setTableUpdating] = useState(false);

  const [moveCommand] = useMutation(MOVE_COMMAND);
  const [reorderCommand] = useMutation(REORDER_COMMAND);
  const [reorderCategory] = useMutation(REORDER_CATEGORY);

  const { data, loading, error } = useQuery(GET_PROJECT, {
    variables: {
      getProjectId: id,
    },
  });

  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useQuery(GET_CATEGORIES_WITH_COMMANDS, {
    variables: {
      projectId: id,
    },
  });

  const {
    data: commandData,
    loading: commandLoading,
    error: commandError,
  } = useQuery(GET_UNCATEGORIZED_COMMANDS, {
    variables: {
      projectId: id,
    },
  });

  if (
    loading ||
    error ||
    categoryLoading ||
    categoryError ||
    commandLoading ||
    commandError
  )
    return null;

  const projectData = data?.getProject;
  const categories = categoryData?.getCategoriesWithCommands;
  const commands = commandData?.getUncategorizedCommands;

  if (outlet) {
    return <Outlet />;
  }

  const handleOnDragEnd = async (result) => {
    console.log(result);

    if (!result.destination) {
      setIsDragging(false);
      return;
    }

    setTableUpdating(true);

    if (result.type === "CATEGORY") {
      await handleDnDCategory(result);
    } else {
      await handleDnDCommand(result);
    }

    await client.refetchQueries({
      include: [GET_CATEGORIES_WITH_COMMANDS, GET_UNCATEGORIZED_COMMANDS],
    });

    setTableUpdating(false);
    setIsDragging(false);

    enqueueSnackbar("Command has been successfully moved", {
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      autoHideDuration: 3000,
      variant: "success",
    });
  };

  const handleDnDCommand = async (result) => {
    const { source, destination, draggableId: editedCommandId } = result;

    const allCommandsFlattened = [...commands];

    categories.forEach((category) => {
      category.commands.forEach((command) => {
        allCommandsFlattened.push(command);
      });
    });

    const destinationCategory = categories.find(
      (category) => category.id === destination.droppableId
    );

    let priorCommandId =
      destinationCategory?.commands?.[destination.index - 1]?.id || null;

    let ulteriorCommandId =
      destinationCategory?.commands?.[destination.index + 1]?.id || null;

    if (priorCommandId === editedCommandId) {
      priorCommandId = destinationCategory?.commands?.[destination.index]?.id;
    } else if (ulteriorCommandId === editedCommandId) {
      ulteriorCommandId =
        destinationCategory?.commands?.[destination.index]?.id;
    }

    const sourceId =
      source.droppableId === "droppable-uncategorized"
        ? null
        : source.droppableId;

    const destinationId =
      destination.droppableId === "droppable-uncategorized"
        ? null
        : destination.droppableId;

    if (sourceId || destinationId) {
      if (sourceId !== destinationId) {
        await moveCommand({
          variables: {
            commandId: editedCommandId,
            categoryId: destinationId || null,
          },
        });
      }
    }

    if (priorCommandId || ulteriorCommandId) {
      await reorderCommand({
        variables: {
          selectedCommandId: editedCommandId,
          priorCommandId,
          ulteriorCommandId,
        },
      });
    }
  };

  const handleDnDCategory = async (result) => {
    const { destination, draggableId: editedCategoryId } = result;

    let priorCategoryId = categories?.[destination.index - 1]?.id || null;

    let ulteriorCategoryId = categories?.[destination.index + 1]?.id || null;

    if (priorCategoryId === editedCategoryId) {
      priorCategoryId = categories?.[destination.index]?.id;
    }

    if (ulteriorCategoryId === editedCategoryId) {
      ulteriorCategoryId = categories?.[destination.index]?.id;
    }

    if (priorCategoryId || ulteriorCategoryId) {
      await reorderCategory({
        variables: {
          selectedCategoryId: editedCategoryId,
          priorCategoryId,
          ulteriorCategoryId,
        },
      });
    }
  };

  return (
    <Page title="Edit project">
      <Container>
        <Breadcrumb breadcrumbRoutes={breadcrumbRoutes().projectsDetail} />
        <ProjectHeader
          name={projectData.name}
          id={projectData.id}
          status={projectData.status}
        />
        <Box
          display="flex"
          justifyContent="end"
          flexWrap="wrap"
          gap={{ xs: "12px", md: "30px" }}
          marginTop={{ xs: "24px", sm: "12px", md: "0px" }}
        >
          <Button
            component={Link}
            to="create-command"
            variant="contained"
            sx={{ width: { xs: "auto", md: "147px" } }}
          >
            + New command
          </Button>
          <Button
            variant="contained"
            sx={{ width: { xs: "auto", md: "147px" } }}
            onClick={() => {
              setOpenAddCategory(true);
            }}
          >
            + New category
          </Button>
        </Box>
        {categories.length > 0 || commands.length > 0 ? (
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: "absolute",
                borderRadius: "5px",
                marginTop: "10px",
              }}
              open={tableUpdating}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <DragDropContext
              onDragEnd={handleOnDragEnd}
              onDragStart={() => setIsDragging(true)}
            >
              <Box marginBottom="16px" minWidth={{ xs: "480px", sm: "auto" }}>
                {categories.length > 0 ? (
                  <>
                    {categories?.map((category, index) => (
                      <DroppableAndDraggable
                        key={category?.id}
                        category={category}
                        index={index}
                      />
                    ))}
                  </>
                ) : (
                  <Grid
                    container
                    marginY="24px"
                    paddingX={{ xs: "0", md: "42px" }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        fontSize="12px"
                        color="rgb(99, 115, 129)"
                      >
                        Currently zero category
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {(commands.length > 0 || isDragging) && (
                  <>
                    <Grid
                      container
                      marginY="16px"
                      paddingX={{ xs: "0", md: "42px" }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="body1" color="#4C7DE7">
                          Uncategorized Commands
                        </Typography>
                      </Grid>
                    </Grid>

                    <Droppable
                      droppableId="droppable-uncategorized"
                      type="COMMAND"
                      direction="vertical"
                    >
                      {/* Uncategorized Command Draggables */}
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {commands.map((command, index) => (
                            <div key={command.id} style={{ marginTop: 10 }}>
                              <Draggable draggableId={command.id} index={index}>
                                {(dragProvided, snapshot) => {
                                  return (
                                    <DnDCommandCard
                                      dndProps={{
                                        ref: dragProvided.innerRef,
                                        snapshot: snapshot,
                                        dragProps: dragProvided.draggableProps,
                                        handleProps:
                                          dragProvided.dragHandleProps,
                                      }}
                                      command={command}
                                    />
                                  );
                                }}
                              </Draggable>
                            </div>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </>
                )}
              </Box>
            </DragDropContext>
          </Box>
        ) : (
          <ProjectsHelp />
        )}
      </Container>
      {openAddCategory && (
        <AddCategoryPopup
          setOpenAddCategory={setOpenAddCategory}
          openAddCategory={openAddCategory}
          pId={id}
        />
      )}
    </Page>
  );
};

export default ProjectDetail;

import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme/theme";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { init } from "commandbar";
init("22d41299");

const authMiddleware = new ApolloLink.from([
  (operation, forward) => {
    // add the authorization to the headers
    const token = localStorage.getItem("accessToken");
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return forward(operation);
  },
]);

const _httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL || "http://localhost:3001/graphql",
});
const httpLink = ApolloLink.from([authMiddleware, _httpLink]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>
);

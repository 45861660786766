import { gql } from "@apollo/client";

export const MY_COMPANY = gql`
  query myCompany {
    myCompany {
      name
      employeeCount
      id
      admin {
        id
        firstName
        lastName
        email
      }
      users {
        id
        firstName
        lastName
        email
        isInvited
      }
    }
  }
`;
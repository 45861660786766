import React, { createContext } from "react";
import { UserContext } from "./UserProvider";

export const OnboardingContext = createContext();

function OnboardingProvider({ children }) {
  const { myProfile } = React.useContext(UserContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [companyName, setCompanyName] = React.useState("");
  const [numberOfEmployee, setNumberOfEmployee] = React.useState("");
  const [roleOfEmployee, setRoleOfEmployee] = React.useState("");
  const [projectName, setProjectName] = React.useState("");

  React.useEffect(() => {
    if (myProfile?.onboardingStep === "CREATED_PROJECT") {
      return setActiveStep(5);
    }

    if (myProfile?.onboardingStep === "CREATED_COMPANY") {
      return setActiveStep(4);
    }
  }, [myProfile]);

  return (
    <OnboardingContext.Provider
      value={{
        activeStep,
        companyName,
        numberOfEmployee,
        roleOfEmployee,
        projectName,
        setActiveStep,
        setCompanyName,
        setNumberOfEmployee,
        setRoleOfEmployee,
        setProjectName,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

export default OnboardingProvider;

import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation CreateProject($data: ProjectCreateInput!) {
    createProject(data: $data) {
      id
      name
      status
      commandsCount
    }
  }
`;
export const DELETE_PROJECT = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;
export const EDIT_PROJECT = gql`
  mutation editProject($data: ProjectEditInput!, $id: ID!) {
    editProject(id: $id, data: $data) {
      id
      name
      status
      commandsCount
      fontFace
      fontColor
      fontUrl
      fontSize
      prompt
    }
  }
`;

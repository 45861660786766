import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useApolloClient, useMutation } from "@apollo/client";
import { SEE_PAGE_TOP_BANNER } from "../graphql/mutation/auth";
import { MY_PROFILE } from "../graphql/query/auth";

const HeaderBox = styled(Box)(({ theme }) => ({
  background: "#eef1ff",
  border: "1px solid rgba(80, 80, 80, 0.1)",
  borderRadius: "2px",
  padding: "20px 29px",
  marginBottom: "39px",
  [theme.breakpoints.down("md")]: {
    padding: "16px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 12px",
  },
}));

function Header({ title, description, pageName }) {
  const client = useApolloClient();
  const [seeTopPageBanner] = useMutation(SEE_PAGE_TOP_BANNER, {
    variables: {
      pageSlug: pageName,
    },
  });
  const handleTopBanner = () => {
    seeTopPageBanner().then(({ data }) => {
      client.writeQuery({
        query: MY_PROFILE,
        data: {
          myProfile: {
            ...data.seePageTopBanner,
            __typename: "MyProfile",
          },
        },
      });
    });
  };
  return (
    <HeaderBox>
      <Grid container>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          gap="8px"
        >
          <Typography variant="h1" fontSize="14px" color="#1B5ECC">
            {title}
          </Typography>
          <CloseIcon
            fontSize="small"
            sx={{ alignSelf: "start", cursor: "pointer" }}
            onClick={() => {
              handleTopBanner();
            }}
          />
        </Box>
      </Grid>
      <Typography
        variant="body2"
        fontSize="12px"
        lineHeight="28px"
        color="#1E293B"
      >
        {description}
      </Typography>
    </HeaderBox>
  );
}

export default Header;

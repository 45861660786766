import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES_WITH_COMMANDS } from "../../graphql/query/category";

const BasicView = ({
  handleCreateCommand,
  commandValues,
  setCommandValues,
  type,
}) => {
  const { id } = useParams();

  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useQuery(GET_CATEGORIES_WITH_COMMANDS, {
    variables: {
      projectId: id,
    },
  });

  if (categoryLoading || categoryError) return null;

  const categories = categoryData?.getCategoriesWithCommands || [];
  const options = [
    { name: "No category", type: "CUSTOM_OPTION" },
    ...categories,
  ];

  const handleCategoryChange = (value) => {
    if (value.type === "CUSTOM_OPTION" && value.name === "No category") {
      return { id: null, name: "No category" };
    }

    return value;
  };

  const commandValuesHandler = (event, handleChange) => {
    commandValues[event.target.name] = event.target.value;
    setCommandValues(commandValues);
    handleChange(event);
  };
  return (
    <Formik
      initialValues={{
        title: commandValues?.title || "",
        description: commandValues?.description || "",
        alwaysShow: commandValues?.alwaysShow || false,
        isRecommended: commandValues?.isRecommended || false,
        keywords: commandValues?.keywords || "",
        category: commandValues?.category || { id: null, name: "" },
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required("Command title is required"),
        description: Yup.string().max(255),
        alwaysShow: Yup.boolean(),
        isRecommended: Yup.boolean(),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values) => {
        handleCreateCommand();
      }}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap="24px">
            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" color="#575F6E">
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  id="title"
                  name="title"
                  onChange={(event) =>
                    commandValuesHandler(event, handleChange)
                  }
                  onBlur={handleBlur}
                  type="text"
                  value={values.title}
                  fullWidth
                  variant="outlined"
                  placeholder="e.g create a new user"
                  inputProps={{
                    style: {
                      padding: "6px 16px",
                      height: "40px",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" color="#575F6E">
                  Description (optional):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  id="description"
                  name="description"
                  onChange={(event) =>
                    commandValuesHandler(event, handleChange)
                  }
                  onBlur={handleBlur}
                  type="text"
                  value={values.description}
                  fullWidth
                  variant="outlined"
                  placeholder="Description of the command"
                  inputProps={{
                    style: {
                      padding: "6px 16px",
                      height: "40px",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" color="#575F6E">
                  Always show (optional):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} display="flex" alignItems="center">
                <FormControlLabel
                  id="alwaysShow"
                  name="alwaysShow"
                  onChange={(event, value) => {
                    commandValues["alwaysShow"] = value;
                    setCommandValues(commandValues);
                    handleChange(event);
                  }}
                  checked={values.alwaysShow || false}
                  onBlur={handleBlur}
                  type="checkbox"
                  control={<Checkbox />}
                  color="#575F6E"
                  label={
                    <Typography variant="body1" color="#575F6E">
                      Always show in popup
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" color="#575F6E">
                  Recommended (optional):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} display="flex" alignItems="center">
                <FormControlLabel
                  id="isRecommended"
                  name="isRecommended"
                  onChange={(event, value) => {
                    commandValues["isRecommended"] = value;
                    setCommandValues(commandValues);
                    handleChange(event);
                  }}
                  checked={values.isRecommended || false}
                  onBlur={handleBlur}
                  type="checkbox"
                  control={<Checkbox />}
                  color="#575F6E"
                  label={
                    <Typography variant="body1" color="#575F6E">
                      Show under recommended section
                    </Typography>
                  }
                />
              </Grid>
            </Grid>

            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" color="#575F6E">
                  Keywords (optional):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  error={Boolean(touched.keywords && errors.keywords)}
                  helperText={touched.keywords && errors.keywords}
                  id="keywords"
                  name="keywords"
                  onChange={(event) =>
                    commandValuesHandler(event, handleChange)
                  }
                  onBlur={handleBlur}
                  type="text"
                  value={values.keywords}
                  fullWidth
                  variant="outlined"
                  placeholder="keywords seperated by space"
                  inputProps={{
                    style: {
                      padding: "6px 16px",
                      height: "40px",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" color="#575F6E">
                  Category (optional):
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Autocomplete
                  id="category"
                  name="category"
                  options={options}
                  getOptionLabel={(option) => option.name}
                  value={values.category}
                  onChange={(e, value) => {
                    setFieldValue(
                      "category",
                      handleCategoryChange(value || options[0])
                    );
                    commandValues["category"] = handleCategoryChange(
                      value || options[0]
                    );
                    setCommandValues(commandValues);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.category && errors.category)}
                      helperText={touched.category && errors.category}
                      variant="outlined"
                      label="Select Category"
                      placeholder="Select category"
                      size="medium"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={8}
              marginTop="32px"
              display="flex"
              justifyContent="end"
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                sx={{ width: "92px" }}
              >
                {type === "edit" ? "Edit!" : "Create!"}
              </Button>
            </Grid>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default BasicView;

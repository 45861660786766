import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { UserContext } from "../../context/UserProvider";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_ME, EDIT_PROFILE } from "../../graphql/mutation/auth";
import authService from "../../services/authService";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function ProfileTabPanel() {
  const [open, setOpen] = React.useState(false);
  const { myProfile } = React.useContext(UserContext);

  const client = useApolloClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [editProfile] = useMutation(EDIT_PROFILE);
  const [deleteMe] = useMutation(DELETE_ME);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await deleteMe();
    authService.logout(client);

    enqueueSnackbar("Account has been successfully deleted", {
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      autoHideDuration: 3000,
      variant: "warning",
    });

    navigate("/");
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: myProfile?.firstName,
          lastName: myProfile?.lastName,
          email: myProfile?.email,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .min(2, "Name is too short")
            .max(50, "Name is too long")
            .required("Name is required"),
          lastName: Yup.string()
            .min(2, "Last name is too short")
            .max(50, "Last name is too long")
            .required("Last name is required"),
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
        })}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          await editProfile({
            variables: {
              data: {
                firstName: values?.firstName,
                lastName: values?.lastName,
              },
            },
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Box sx={{ mt: 4, maxWidth: "520px" }}>
            <form noValidate onSubmit={handleSubmit}>
              <Grid container columnSpacing="24px">
                <Grid item xs={6}>
                  <Typography variant="body1" color="#1A1919">
                    First name
                  </Typography>
                  <TextField
                    margin="normal"
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    required
                    fullWidth
                    id="firstName"
                    placeholder="Enter your first name"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.firstName}
                    autoComplete="given-name"
                    sx={{ mb: 3 }}
                    inputProps={{
                      style: {
                        padding: "12px 16px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" color="#1A1919">
                    Last name
                  </Typography>
                  <TextField
                    margin="normal"
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    required
                    fullWidth
                    id="lastName"
                    placeholder="Enter your last name"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.lastName}
                    autoComplete="family-name"
                    sx={{ mb: 3 }}
                    inputProps={{
                      style: {
                        padding: "12px 16px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color="#1A1919">
                  Email
                </Typography>
                <TextField
                  margin="normal"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  required
                  fullWidth
                  id="email"
                  placeholder="Enter your email address"
                  disabled
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  value={values.email}
                  autoComplete="email"
                  inputProps={{
                    style: {
                      padding: "12px 16px",
                    },
                  }}
                />
              </Grid>
              <Typography
                variant="body1"
                fontSize="12px"
                color="#666666"
                marginBottom="24px"
              >
                After updating, you will receive a confirmation email on your
                current address
              </Typography>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  width: "120px",
                  color: "#ffffff",
                  backgroundColor: "#4C7DE7",
                  "&:hover": {
                    backgroundColor: "#3d6dd4",
                  },
                }}
              >
                Update
              </Button>
            </form>
          </Box>
        )}
      </Formik>
      <Box marginTop="58px">
        <Typography variant="body1" color="#1A1919" marginBottom="24px">
          Danger Zone
        </Typography>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          sx={{
            minWidth: "184px",
            color: "#ffffff",
            backgroundColor: "#DB504A",
            "&:hover": {
              backgroundColor: "#C43F39",
            },
          }}
        >
          Delete my account
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body1" fontWeight="500">
            Really delete this account?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1" fontSize="12px">
              If you delete your account, you will no longer be able to sign in,
              and all of your data will be deleted. Deleting your account is
              permanent and non-recoverable action.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0px 24px 24px 24px" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              color: "rgba(21, 25, 32, 0.5)",
              border: "1px solid rgba(86, 103, 137, 0.26)",
              borderRadius: "8px",
              "&:hover": {
                borderColor: "rgba(21, 25, 32, 0.5)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            variant="contained"
            sx={{
              color: "#ffffff",
              backgroundColor: "#DB504A",
              "&:hover": {
                backgroundColor: "#C43F39",
              },
            }}
          >
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProfileTabPanel;

// import * as Sentry from "@sentry/react";
import axios from "../utils/axios";
import jwtDecode from "jwt-decode";
import {
  AUTHENTICATE_USER,
  GOOGLE_OAUTH,
  SIGNUP_USER,
} from "../graphql/mutation/auth";
import { IS_LOGGED_IN, MY_PROFILE } from "../graphql/query/auth";
class AuthService {
  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }
  loginWithGoogle = async (client, googleAccessToken) =>
    new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: GOOGLE_OAUTH,
          variables: { googleAccessToken },
        })
        .then(({ data }) => {
          const reply = data.authenticate;
          this.setSession(reply.token);
          const userObject = { ...reply.user, token: reply.token };
          localStorage.setItem("loggedInUser", JSON.stringify(userObject));

          resolve(userObject);
        })
        .then(() => {
          client.refetchQueries({
            include: [MY_PROFILE],
          });
        }).catch((error) => {
          reject(error.message.replace("GraphQL error: ", ""));
        });
    });

  loginWithEmailAndPassword = (client, email, password) =>
    new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: AUTHENTICATE_USER,
          variables: { email, password },
        })
        .then(({ data }) => {
          const reply = data.authenticate;
          this.setSession(reply.token);
          const userObject = { ...reply.user, token: reply.token };
          localStorage.setItem("loggedInUser", JSON.stringify(userObject));

          resolve(userObject);
        })
        .then(() => {
          client.refetchQueries({
            include: [MY_PROFILE],
          });
        }).catch((error) => {
          reject(error.message.replace("GraphQL error: ", ""));
        });
    });

  //   loginInWithToken = () =>
  //     new Promise((resolve, reject) => {
  //       const existingUserData = JSON.parse(localStorage.getItem("loggedInUser"));
  //       Sentry.setUser({ email: existingUserData.email });
  //       resolve(existingUserData);
  //     });

  logout = (client) => {
    this.setSession(null);
    client.resetStore();
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loggedInUser");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem("accessToken");

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isLoginExpired = async (client) => {
    const { data } = await client.query({
      query: IS_LOGGED_IN, fetchPolicy: 'no-cache'
    })
    return !!this.getAccessToken() && !data.isLoggedIn
  }

  register = (client, data) =>
    new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: SIGNUP_USER,
          variables: {
            data,
          },
        })
        .then(({ data }) => {
          const reply = data.signupUser;
          this.setSession(reply.token);
          const userObject = { ...reply.user, token: reply.token };
          localStorage.setItem("loggedInUser", JSON.stringify(userObject));

          resolve(userObject);
        })
        .then(() => {
          client.refetchQueries({
            include: [MY_PROFILE],
          });
        })
        .catch((error) => {
          reject(error.message.replace("GraphQL error: ", ""));
        });
    });
}

const authService = new AuthService();

export default authService;

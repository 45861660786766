import React from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { OnboardingContext } from "../../../context/OnboardingProvider";
import OnboardingCard from "../OnboardingCard";
import OnboardingButton from "../OnboardingButton";

const employeeNumber = [
  { label: "Less than 5", value: "lessThan5" },
  { label: "5-10", value: "5-10" },
  { label: "10-25", value: "10-25" },
  { label: "25-100", value: "25-100" },
  { label: "100-500", value: "100-500" },
  { label: "More than 500", value: "moreThan500" },
];

function Step3() {
  const { setActiveStep, companyName, numberOfEmployee, setNumberOfEmployee } =
    React.useContext(OnboardingContext);

  return (
    <OnboardingCard sx={{ padding: "19px 33px 40px" }}>
      <Typography variant="h2" fontSize="18px" color="#303030">
        Select number of employees
      </Typography>
      <Typography variant="body1" color="#667080">
        How many people work at {companyName || "your company"}?
      </Typography>
      <Box marginTop="25px" marginBottom="29px">
        <RadioGroup
          value={numberOfEmployee}
          onChange={(e) => setNumberOfEmployee(e.target.value)}
          sx={{ gap: "8px" }}
        >
          {employeeNumber.map((number) => (
            <FormControlLabel
              key={number.value}
              value={number.value}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "18px",
                    },
                  }}
                />
              }
              label={number.label}
              sx={{
                margin: 0,
                transition: "border .25s ease",
                border: "1px solid rgba(86, 103, 137, 0.26)",
                borderRadius: "8px",
                ".MuiFormControlLabel-label": {
                  color: "#101828",
                },
                "&:hover": {
                  borderColor: "#3069fe",
                  ".MuiFormControlLabel-label": {
                    color: "#000000",
                  },
                },
              }}
            />
          ))}
        </RadioGroup>
      </Box>
      <Box textAlign="center">
        <OnboardingButton
          disabled={!numberOfEmployee}
          handleClick={() => setActiveStep(3)}
        >
          Next
        </OnboardingButton>
      </Box>
    </OnboardingCard>
  );
}

export default Step3;

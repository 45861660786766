import * as React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLoginButton } from "react-social-login-buttons";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import authService from "../../services/authService";
import { useApolloClient } from "@apollo/client";
import { GoogleLogin } from "@leecheuk/react-google-login";
import { gapi } from "gapi-script";
import Page from "../../components/Page";
import {
  BenefitsLifeBuoyIcon,
  BenefitsSearchIcon,
  BenefitsToolIcon,
  BenefitsUserIcon,
} from "../../assets/icons";

const clientId =
  "957363032767-vj3c4v8mm54eatn9q6v25slrknuv6g72.apps.googleusercontent.com";

function Copyright(props) {
  return (
    <Typography variant="body1" color="#666666" {...props}>
      {"© 2022 Magny. Our privacy policy is "}
      <Typography component={Link} color="inherit" to="">
        here.
      </Typography>
    </Typography>
  );
}

export default function SignIn({ onSubmitSuccess }) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = async (res) => {
    await authService
      .loginWithGoogle(client, res.tokenId)
      .then(() => navigate("/"))
      .catch((error) =>
        enqueueSnackbar(error, {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          autoHideDuration: 3000,
          variant: "error",
        })
      );
  };

  const onFailure = (err) => {
    enqueueSnackbar("There was a problem with Google Service", {
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      autoHideDuration: 3000,
      variant: "error",
    });
  };

  React.useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "email",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  return (
    <Page title="Login">
      <Grid container height="100vh">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            px: { xs: 2, md: 8 },
            pt: { xs: 1, md: 4 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
              })}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await authService.loginWithEmailAndPassword(
                    client,
                    values.email,
                    values.password
                  );
                  onSubmitSuccess();
                } catch (error) {
                  const message =
                    `${error.toString()}` || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                  enqueueSnackbar(message, {
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top",
                    },
                    autoHideDuration: 3000,
                    variant: "error",
                  });
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Box sx={{ mt: 1 }}>
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      textAlign: "start",
                      mb: "16px",
                    }}
                  >
                    <img
                      src="/logo/magny-landing-logo.png"
                      style={{ width: "50%", minWidth: "225px" }}
                      alt="magny  logo"
                    />
                  </Box>
                  <Box sx={{ textAlign: "start", mt: 3, mb: 4 }} fullWidth>
                    <Typography
                      component="h1"
                      variant="h4"
                      fontWeight={500}
                      fontSize="32px"
                      color="#1A1919"
                      sx={{ pb: 1 }}
                    >
                      Log in
                    </Typography>
                    <Typography variant="body1" color="#666666">
                      Enter your credentials to access your account
                    </Typography>
                  </Box>
                  <GoogleLogin
                    clientId={clientId}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                    render={({ onClick }) => (
                      <GoogleLoginButton
                        size="48px"
                        text="Log in with Google"
                        onClick={onClick}
                        style={{
                          padding: "12px, 16px, 12px, 16px",
                          display: "flex",
                          justifyContent: "center",
                          boxShadow:
                            "0px 3px 1px -2px rgba(0, 0, 0, 0.06), 0px 5px 3px -2px rgba(0, 0, 0, 0.02)",
                          border: "1px solid rgba(222, 222, 222, 1)",
                          borderRadius: "8px",
                          color: "#4D4D4D",
                          width: "100%",
                          margin: 0,
                        }}
                      />
                    )}
                  />
                  <Divider sx={{ marginY: 4 }}>or</Divider>
                  <form noValidate onSubmit={handleSubmit}>
                    <Typography variant="body1" color="#1A1919" mb={1}>
                      Email address
                    </Typography>
                    <TextField
                      margin="none"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      required
                      fullWidth
                      id="email"
                      placeholder="Email Address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="email"
                      value={values.email}
                      autoComplete="email"
                      autoFocus
                      inputProps={{
                        style: {
                          padding: "12px 16px",
                        },
                      }}
                      sx={{ mb: 3 }}
                    />
                    <Grid container justifyContent="space-between">
                      <Typography variant="body1" color="#1A1919" mb={1}>
                        Password
                      </Typography>
                      <Typography
                        component={Link}
                        to=""
                        variant="body1"
                        fontWeight={500}
                        color="#3069FE"
                        sx={{
                          textDecoration: "none",
                          "&:hover": { textDecoration: "underline" },
                        }}
                      >
                        Forgot password?
                      </Typography>
                    </Grid>
                    <TextField
                      margin="none"
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      name="password"
                      placeholder="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      id="password"
                      autoComplete="current-password"
                      inputProps={{
                        style: {
                          padding: "12px 16px",
                        },
                      }}
                      sx={{ mb: 3 }}
                    />
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember information"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      sx={{
                        mt: 3,
                        mb: 4,
                        height: 48,
                        p: "12px, 16px",
                        borderRadius: "8px",
                      }}
                    >
                      Login
                    </Button>
                    <Grid container>
                      <Typography variant="body1" color="#1A1919">
                        Not a member? &nbsp;
                      </Typography>
                      <Typography
                        component={Link}
                        to="/signup"
                        variant="body1"
                        fontWeight={500}
                        color="#3069FE"
                        sx={{
                          textDecoration: "none",
                          "&:hover": { textDecoration: "underline" },
                        }}
                      >
                        Sign up now
                      </Typography>
                    </Grid>
                  </form>
                  <Copyright sx={{ mt: 8, mb: 4 }} />
                </Box>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: { xs: "none", md: "block" },
            bgcolor: "gray.200",
            px: 8,
            pt: 10,
          }}
        >
          <img
            src="/logo/magny-landing-logo.png"
            style={{ width: "100%", maxWidth: "276px" }}
            alt="magny  logo"
          />
          <Typography
            variant="body1"
            color="rgba(0, 0, 0, 0.72)"
            maxWidth="520px"
            marginTop="24px"
          >
            Magny is a navigation, universal search and helper for SaaS apps in
            order to help users understand and onboard apps easier, faster and
            in a convenient manner.
          </Typography>
          <Box
            marginTop="40px"
            display="flex"
            flexDirection="column"
            rowGap="6px"
          >
            <Box display="flex" alignItems="center" columnGap="12px">
              <BenefitsSearchIcon />
              <Typography variant="body1" color="rgba(0, 0, 0, 0.72)">
                Make searches via commands and perform actions
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" columnGap="12px">
              <BenefitsToolIcon />
              <Typography variant="body1" color="rgba(0, 0, 0, 0.72)">
                Provide recommendations and new features to users
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" columnGap="12px">
              <BenefitsLifeBuoyIcon />
              <Typography variant="body1" color="rgba(0, 0, 0, 0.72)">
                Help users discover your features and easily onboard
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" columnGap="12px">
              <BenefitsUserIcon />
              <Typography variant="body1" color="rgba(0, 0, 0, 0.72)">
                Turn your average user into a power user
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
}

import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  OutlinedInput,
  Button,
  Tooltip,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { MY_PROJECTS } from "../../graphql/query/project";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { CopyToClipboardIcon, QuestionMarkIcon } from "../../assets/icons";
import ProjectOverview from "./ProjectOverview";
import { EDIT_PROJECT } from "../../graphql/mutation/project";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const fontFaces = ["Comic Sans", "New York Times"];
const fontSize = ["Small", "Medium (Default)", "Large"];
const fontColor = ["Red", "Blue", "Black (Default)"];
const SCRIPT_HOST =
  process.env.REACT_APP_SCRIPT_HOST || "http://localhost:3000";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#4C7DE7",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "340px",
    padding: "12px",
    color: "#FFFFFF",
    background: "#4C7DE7",
    borderRadius: "8px",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
  },
});

const SettingsView = () => {
  const [firstRender, setFirstRender] = React.useState(true);
  const [selectedProject, setSelectedProject] = React.useState("");
  const [fontFaceVar, setFontFace] = React.useState("");
  const [fontSizeVar, setFontSize] = React.useState("");
  const [fontColorVar, setFontColor] = React.useState("");
  const [promptValue, setPromptValue] = React.useState("");
  const { data } = useQuery(MY_PROJECTS);
  const { enqueueSnackbar } = useSnackbar();
  const isPremium = false;

  const [editProject] = useMutation(EDIT_PROJECT, {
    onCompleted: (data) => {
      enqueueSnackbar("Project has been successfully saved", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        autoHideDuration: 3000,
        variant: "success",
      });
    },
  });

  const myProjects = data?.myProjects;

  useEffect(() => {
    if (myProjects && firstRender) {
      setSelectedProject(myProjects[0]);
      setFontColor(myProjects[0].fontColor || "");
      setFontFace(myProjects[0].fontFace || "");
      setFontSize(myProjects[0].fontSize || "");
      setPromptValue(myProjects[0].prompt || "");
      setFirstRender(false);
    }
  }, [myProjects, firstRender]);

  const handleChange = (event, type) => {
    let {
      target: { value },
    } = event;

    if (type === "fontFace") {
      setFontFace(value);
    }
    if (type === "fontSize") {
      setFontSize(value);
    }
    if (type === "fontColor") {
      setFontColor(value);
    }
    if (type === "projectId") {
      const innerSelectedProject = myProjects.find(
        (project) => project.id === value
      );

      setSelectedProject(innerSelectedProject);
      setFontColor(innerSelectedProject.fontColor);
      setFontFace(innerSelectedProject.fontFace);
      setFontSize(innerSelectedProject.fontSize);
      setPromptValue(innerSelectedProject.prompt);
    }
  };
  const handlePropmt = (event) => {
    setPromptValue(event);
  };

  const handleSaveButton = async () => {
    await editProject({
      variables: {
        id: selectedProject?.id,
        data: {
          fontColor: fontColorVar,
          fontSize: fontSizeVar,
          fontFace: fontFaceVar,
          prompt: promptValue,
        },
      },
    });
  };
  const handleCancelButton = () => {
    setFontColor("");
    setFontFace("");
    setFontSize("");
    setPromptValue("");
  };

  const scriptDiv = `<div id="magny" data-projectid="${selectedProject?.id}"></div>`;
  const scriptLink = `<link href="${SCRIPT_HOST}/index.css" rel="stylesheet" type="text/css"/>`;
  const scriptJS = `<script src="${SCRIPT_HOST}/index.js"></script>`;
  const script = `${scriptDiv}
${scriptJS}
${scriptLink}`;

  if (!selectedProject) return null;

  return (
    <Grid container rowGap="32px" maxWidth={{ xs: "auto", md: "960px" }}>
      <Grid
        container
        spacing="8px"
        display="flex"
        alignItems="center"
        maxWidth={{ xs: "auto", md: "520px", lg: "720px" }}
      >
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          display="flex"
          alignItems="start"
          justifyContent={{ xs: "start", md: "end" }}
        >
          <Typography variant="body1" marginRight={{ md: "48px", lg: "57px" }}>
            View settings for
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <FormControl sx={{ width: "100%", maxWidth: 300 }}>
            <InputLabel id="demo-multiple-name-label">Project Name</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={selectedProject.id}
              onChange={(e) => handleChange(e, "projectId")}
              input={<OutlinedInput label="Project Name" />}
              MenuProps={MenuProps}
            >
              {myProjects?.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        rowGap="16px"
        maxWidth={{ xs: "auto", md: "520px", lg: "720px" }}
      >
        <Grid container spacing="8px" display="flex" alignItems="center">
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            display="flex"
            alignItems="start"
            justifyContent={{ xs: "start", md: "end" }}
          >
            <Typography
              variant="body1"
              marginLeft={{ xs: "48px", md: "0" }}
              textAlign={{ xs: "left", md: "right" }}
              marginRight={{ md: "48px", lg: "57px" }}
            >
              Font face
              {!isPremium && (
                <Typography
                  variant="body1"
                  fontSize="12px"
                  color="#575F6E"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  (only in Pro plan)
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            sx={{
              marginLeft: { xs: "46px", md: 0 },
            }}
            display="flex"
            alignItems="center"
            gap="27px"
          >
            <FormControl
              sx={{
                width: "100%",
                maxWidth: 300,
              }}
              disabled={!isPremium}
            >
              <InputLabel id="demo-multiple-name-label">Font face</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={fontFaceVar}
                onChange={(e) => handleChange(e, "fontFace")}
                input={<OutlinedInput label="Font face" />}
                MenuProps={MenuProps}
              >
                {fontFaces.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!isPremium && (
              <Typography
                variant="body1"
                fontSize="12px"
                color="#575F6E"
                sx={{ display: { xs: "none", lg: "block" } }}
              >
                (only in Pro plan)
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing="8px" display="flex" alignItems="center">
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            display="flex"
            alignItems="start"
            justifyContent={{ xs: "start", md: "end" }}
          >
            <Typography
              variant="body1"
              marginLeft={{ xs: "48px", md: "0" }}
              textAlign={{ xs: "left", md: "right" }}
              marginRight={{ md: "48px", lg: "57px" }}
            >
              Font size
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            sx={{
              marginLeft: { xs: "46px", md: 0 },
            }}
          >
            <FormControl
              sx={{
                width: "100%",
                maxWidth: 300,
              }}
            >
              <InputLabel id="demo-multiple-name-label">Font size</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={fontSizeVar}
                onChange={(e) => handleChange(e, "fontSize")}
                input={<OutlinedInput label="Font size" />}
                MenuProps={MenuProps}
              >
                {fontSize.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing="8px" display="flex" alignItems="center">
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            display="flex"
            alignItems="start"
            justifyContent={{ xs: "start", md: "end" }}
          >
            <Typography
              variant="body1"
              marginLeft={{ xs: "48px", md: "0" }}
              textAlign={{ xs: "left", md: "right" }}
              marginRight={{ md: "48px", lg: "57px" }}
            >
              Font color
              {!isPremium && (
                <Typography
                  variant="body1"
                  fontSize="12px"
                  color="#575F6E"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  (only in Pro plan)
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            sx={{
              marginLeft: { xs: "46px", md: 0 },
            }}
            display="flex"
            alignItems="center"
            gap="27px"
          >
            <FormControl
              sx={{
                width: "100%",
                maxWidth: 300,
              }}
              disabled={!isPremium}
            >
              <InputLabel id="demo-multiple-name-label">Font color</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={fontColorVar}
                onChange={(e) => handleChange(e, "fontColor")}
                input={<OutlinedInput label="Font color" />}
                MenuProps={MenuProps}
              >
                {fontColor.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!isPremium && (
              <Typography
                variant="body1"
                fontSize="12px"
                color="#575F6E"
                sx={{ display: { xs: "none", lg: "block" } }}
              >
                (only in Pro plan)
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing="8px" display="flex" alignItems="center">
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            display="flex"
            alignItems="start"
            justifyContent={{ xs: "start", md: "end" }}
          >
            <Typography
              variant="body1"
              marginLeft={{ xs: "48px", md: "0" }}
              textAlign={{ xs: "left", md: "right" }}
              marginRight={{ md: "48px", lg: "57px" }}
            >
              Prompt
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            sx={{
              marginLeft: { xs: "46px", md: 0 },
            }}
          >
            <TextField
              id="outlined-basic"
              placeholder="Search for anything!"
              variant="outlined"
              value={promptValue}
              onChange={(e) => handlePropmt(e.target.value)}
              sx={{
                width: "100%",
                maxWidth: 300,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing="8px" display="flex" alignItems="center">
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            display="flex"
            alignItems="start"
            justifyContent={{ xs: "start", md: "end" }}
          >
            <Typography
              variant="body1"
              marginLeft={{ xs: "48px", md: "0" }}
              textAlign={{ xs: "left", md: "right" }}
              marginRight={{ md: "48px", lg: "57px" }}
            >
              Magny Logo
              {!isPremium && (
                <Typography
                  variant="body1"
                  fontSize="12px"
                  color="#575F6E"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  (only in Pro plan)
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            sx={{
              marginLeft: { xs: "46px", md: 0 },
            }}
            display="flex"
            alignItems="center"
            gap="27px"
          >
            <FormControlLabel
              disabled={!isPremium}
              sx={{ width: "100%", maxWidth: 300 }}
              control={<Checkbox />}
              label={
                isPremium ? "Do not show (only in paid plans)" : "Do not show"
              }
            />
            {!isPremium && (
              <Typography
                variant="body1"
                fontSize="12px"
                color="#575F6E"
                sx={{ display: { xs: "none", lg: "block" } }}
              >
                (only in Pro plan)
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing="8px" display="flex" alignItems="center">
          <Grid item xs={12}>
            <ProjectOverview
              fontColorVar={fontColorVar}
              promptValue={promptValue}
              fontSizeVar={fontSizeVar}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing="8px"
        marginTop="16px"
        display="flex"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Box
            display="flex"
            alignItems={{ xs: "center", lg: "start" }}
            columnGap="12px"
          >
            <Box textAlign={{ xs: "left", md: "right" }}>
              <Typography
                variant="body1"
                marginLeft={{ xs: "0px", md: "46px" }}
              >
                Magny popup widget
              </Typography>
              <Typography
                variant="body1"
                marginLeft={{ xs: "0px", md: "46px" }}
              >
                (Project: {selectedProject?.name})
              </Typography>
            </Box>
            <CustomTooltip
              title="Copy the following script and insert it into your website's HTML source code, between the <head> and </head> tags."
              arrow
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
              >
                <QuestionMarkIcon width="20px" height="20px" />
              </Box>
            </CustomTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box
            sx={{
              position: "relative",
              paddingRight: "48px",
              borderRadius: "8px",
              border: "1px solid #DEDEDE",
              backgroundColor: "ghostwhite",
              marginLeft: { xs: "0px", md: "46px", lg: "0px" },
            }}
          >
            <Typography
              variant="body1"
              color="#1A1919"
              fontSize="13px"
              lineHeight="18px"
            >
              <SyntaxHighlighter
                language="javascript"
                style={docco}
                customStyle={{
                  margin: 0,
                  padding: "16px",
                  borderRadius: "8px",
                }}
              >
                {script}
              </SyntaxHighlighter>
            </Typography>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <CustomTooltip title="Copy to clipboard" arrow>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(script);
                    enqueueSnackbar("Copied to clipboard", {
                      anchorOrigin: {
                        horizontal: "center",
                        vertical: "top",
                      },
                      autoHideDuration: 3000,
                      variant: "success",
                    });
                  }}
                >
                  <CopyToClipboardIcon />
                </IconButton>
              </CustomTooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="end"
        alignItems="center"
        gap="28px"
        marginLeft={{ xs: "0px", md: "46px" }}
        marginTop="48px"
      >
        <Button
          variant="outlined"
          sx={{
            width: "105px",
            border: "1px solid rgba(86, 103, 137, 0.26)",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h5"
            fontSize="12px"
            color="rgba(21, 25, 32, 0.5)"
            onClick={() => handleCancelButton()}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{ width: "105px" }}
          onClick={() => handleSaveButton()}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
export default SettingsView;

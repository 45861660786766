import { gql } from "@apollo/client";

export const GET_COMMAND = gql`
  query getCommand($getCommandId: ID!) {
    getCommand(id: $getCommandId) {
      id
      title
      description
      icon
      hasIcon
      alwaysShow
      isRecommended
      shortcut
      keywords
      actionType
      status
      callback
      link
      openInSameTab
      webhook
      useCount
    }
  }
`;

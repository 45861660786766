import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { UserContext } from "../context/UserProvider";
import Login from "../views/LoginView";
import SignUp from "../views/SignupView";
import Layout from "../pages/Layout";
import Dashboard from "../pages/Dashboard";
import Projects from "../pages/Projects";
import Feedbacks from "../pages/Feedbacks";
import Users from "../pages/Users";
import Settings from "../pages/Settings";
import Onboarding from "../pages/Onboarding";
import Profile from "../pages/Profile";
import ProjectDetail from "../pages/ProjectDetail";
import CreateCommand from "../pages/CreateCommand";
import EditCommand from "../pages/EditCommand";
import Analytics from "../pages/Analytics";

const GuestProtectedRoute = ({ children }) => {
  const { myProfile, loading } = React.useContext(UserContext);
  
  if (loading) return null;

  if (myProfile) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const OffboardProtectedRoute = ({ children }) => {
  const { myProfile, loading } = React.useContext(UserContext);

  if (loading) return null;

  if (!myProfile) {
    return <Navigate to="/login" replace />;
  }

  if (myProfile?.completedOnboarding) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const OnboardProtectedRoute = ({ children }) => {
  const { myProfile, loading } = React.useContext(UserContext);

  if (loading) return null;

  if (!myProfile) {
    return <Navigate to="/login" replace />;
  }

  if (!myProfile?.completedOnboarding) {
    return <Navigate to="/onboarding" replace />;
  }

  return children;
};

const ROUTES = [
  {
    path: "login",
    element: (
      <GuestProtectedRoute>
        <Login />
      </GuestProtectedRoute>
    ),
  },
  {
    path: "signup",
    element: (
      <GuestProtectedRoute>
        <SignUp />
      </GuestProtectedRoute>
    ),
  },
  {
    path: "onboarding",
    element: (
      <OffboardProtectedRoute>
        <Onboarding />
      </OffboardProtectedRoute>
    ),
  },
  {
    path: "/",
    element: (
      <OnboardProtectedRoute>
        <Layout />
      </OnboardProtectedRoute>
    ),
    children: [
      {
        path: "home",
        element: <Dashboard />,
      },
      {
        path: "projects",
        element: <Projects />,
        children: [
          {
            path: ":id",
            element: <ProjectDetail />,
            children: [
              {
                path: "create-command",
                element: <CreateCommand />,
              },
              {
                path: "edit-command/:commandId",
                element: <EditCommand />,
              },
            ],
          },
        ],
      },
      {
        path: "feedbacks",
        element: <Feedbacks />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "audience",
        element: <Users />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
];

const Route = () => {
  let routes = useRoutes(ROUTES);
  return routes;
};

export default Route;

import * as React from "react";
import { Link } from "react-router-dom";
import styled from "@mui/material/styles/styled";
import {
  List,
  Box,
  Popover,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ContactSupportIcon,
  DashboardIcon,
  DiscordCommunityIcon,
  DocumentationIcon,
  FeedbackIcon,
  HiringIcon,
  LogIcon,
  ProductUpdateIcon,
  ProjectIcon,
  SettingsIcon,
  SupportIcon,
  UserIcon,
  AnalyticsLogo,
} from "../assets/icons";
import Search from "./Search";
import Logo from "./Logo";
import { AppBarContext } from "../context/AppBarProvider";

const typographyProps = {
  color: "#344054",
  variant: "h3",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "24px",
};

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  width: "88%",
  borderRadius: "6px",
  gap: "16px",
  padding: "6px 16px",
  margin: "auto",
  "&:hover": { background: "#F2F6FF" },
}));

const StyledPopoverListItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "14px",
  padding: "8px 22px 8px 14px",
  "&:hover": { cursor: "pointer", background: "#F2F6FF" },
}));

function MainListItems({ setMobileOpen, activeTab }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        marginY: "2px",
      }}
    >
      <Box
        paddingY="6px"
        width="88%"
        margin="auto"
        display={{ xs: "block", md: "none" }}
      >
        <Search placeholder="Search" width="100%" />
      </Box>
      <StyledListItemButton
        component={Link}
        to={`/home`}
        onClick={() => setMobileOpen(false)}
        sx={{
          background: activeTab === "/home" ? "#F2F6FF" : "transparent",
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Home" primaryTypographyProps={typographyProps} />
      </StyledListItemButton>
      <StyledListItemButton
        component={Link}
        to={`/projects`}
        onClick={() => setMobileOpen(false)}
        sx={{
          background: activeTab === "/projects" ? "#F2F6FF" : "transparent",
        }}
      >
        <ListItemIcon>
          <ProjectIcon />
        </ListItemIcon>
        <ListItemText
          primary="Projects"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
      <StyledListItemButton
        component={Link}
        to={`/audience`}
        onClick={() => setMobileOpen(false)}
        sx={{
          background: activeTab === "/audience" ? "#F2F6FF" : "transparent",
        }}
      >
        <ListItemIcon>
          <UserIcon />
        </ListItemIcon>
        <ListItemText
          primary="Audience"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
      <StyledListItemButton
        component={Link}
        to={`/feedbacks`}
        onClick={() => setMobileOpen(false)}
        sx={{
          background: activeTab === "/feedbacks" ? "#F2F6FF" : "transparent",
        }}
      >
        <ListItemIcon>
          <FeedbackIcon />
        </ListItemIcon>
        <ListItemText
          primary="Feedbacks"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
      <StyledListItemButton
        component={Link}
        to={`/analytics`}
        onClick={() => setMobileOpen(false)}
        sx={{
          background: activeTab === "/analytics" ? "#F2F6FF" : "transparent",
        }}
      >
        <ListItemIcon>
          <AnalyticsLogo />
        </ListItemIcon>
        <ListItemText
          primary="Analytics"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
      <StyledListItemButton
        onClick={() => setMobileOpen(false)}
        sx={{
          background: activeTab === "/logs" ? "#F2F6FF" : "transparent",
        }}
      >
        <ListItemIcon>
          <LogIcon />
        </ListItemIcon>
        <ListItemText primary="Logs" primaryTypographyProps={typographyProps} />
      </StyledListItemButton>
    </Box>
  );
}

function SecondaryListItems({
  openSupportPopover,
  handleClick,
  openMobileSupport,
  setOpenMobileSupport,
  setMobileOpen,
  activeTab,
}) {
  return (
    <Box>
      <StyledListItemButton
        component={Link}
        to={`/settings`}
        onClick={() => setMobileOpen(false)}
        sx={{
          background: activeTab === "/settings" ? "#F2F6FF" : "transparent",
          marginBottom: "4px",
        }}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText
          primary="Settings"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
      <StyledListItemButton
        sx={{ display: { xs: "none", md: "flex" }, marginBottom: "2px" }}
        id="support-popover"
        onClick={handleClick}
      >
        <ListItemIcon>
          <SupportIcon />
        </ListItemIcon>
        <ListItemText
          primary="Support"
          primaryTypographyProps={typographyProps}
        />
        {openSupportPopover ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      </StyledListItemButton>
      <StyledListItemButton
        sx={{
          display: { xs: "flex", md: "none" },
          marginBottom: { xs: "2px", md: "4px" },
        }}
        onClick={() => setOpenMobileSupport(!openMobileSupport)}
      >
        <ListItemIcon>
          <SupportIcon />
        </ListItemIcon>
        <ListItemText
          primary="Support"
          primaryTypographyProps={typographyProps}
        />
        {openMobileSupport ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </StyledListItemButton>
      <SupportMobile openMobileSupport={openMobileSupport} />
    </Box>
  );
}

function SupportPopover({ id, open, anchorEl, handleClose }) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          width: "213px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "15px 0 10px 0",
          boxShadow: "none",
          border: "1px solid #A0A0A0",
          borderRadius: "7px",
        },
      }}
    >
      <StyledPopoverListItem>
        <DocumentationIcon />
        <Typography variant="body1" color="#344054">
          Documentation
        </Typography>
      </StyledPopoverListItem>
      <StyledPopoverListItem>
        <HiringIcon />
        <Typography variant="body1" color="#344054">
          Magny is hiring!
        </Typography>
      </StyledPopoverListItem>
      <a
        href="https://magny.hellonext.co/"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <StyledPopoverListItem>
          <ProductUpdateIcon />
          <Typography variant="body1" color="#344054">
            Send a feedback
          </Typography>
        </StyledPopoverListItem>
      </a>
      <StyledPopoverListItem>
        <DiscordCommunityIcon />
        <Typography variant="body1" color="#344054">
          Discord community
        </Typography>
      </StyledPopoverListItem>
      <StyledPopoverListItem>
        <ContactSupportIcon />
        <Typography variant="body1" color="#344054">
          Contact Support
        </Typography>
      </StyledPopoverListItem>
    </Popover>
  );
}

function SupportMobile({ openMobileSupport }) {
  return (
    <Box
      sx={{
        transition: "height 200ms",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
      overflow="hidden"
      height={openMobileSupport ? "241px" : "0px"}
    >
      <StyledListItemButton>
        <ListItemIcon>
          <DocumentationIcon
            width="18px"
            height="18px"
            color="rgb(102, 112, 133)"
          />
        </ListItemIcon>
        <ListItemText
          primary="Documentation"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
      <StyledListItemButton>
        <ListItemIcon>
          <HiringIcon width="20px" height="20px" color="rgb(102, 112, 133)" />
        </ListItemIcon>
        <ListItemText
          primary="Magny is hiring!"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
      <a
        href="https://magny.hellonext.co/"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <StyledListItemButton>
          <ListItemIcon>
            <ProductUpdateIcon
              width="20px"
              height="20px"
              color="rgb(102, 112, 133)"
            />
          </ListItemIcon>
          <ListItemText
            primary="Send a feedback"
            primaryTypographyProps={typographyProps}
          />
        </StyledListItemButton>
      </a>
      <StyledListItemButton>
        <ListItemIcon>
          <DiscordCommunityIcon
            width="20px"
            height="20px"
            color="rgb(102, 112, 133)"
          />
        </ListItemIcon>
        <ListItemText
          primary="Discord community"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
      <StyledListItemButton>
        <ListItemIcon>
          <ContactSupportIcon
            width="20px"
            height="20px"
            color="rgb(102, 112, 133)"
          />
        </ListItemIcon>
        <ListItemText
          primary="Contact Support"
          primaryTypographyProps={typographyProps}
        />
      </StyledListItemButton>
    </Box>
  );
}

function Menu({ activeTab }) {
  const { setMobileOpen } = React.useContext(AppBarContext);
  const [openMobileSupport, setOpenMobileSupport] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "support-popover" : undefined;

  return (
    <>
      <Logo setMobileOpen={setMobileOpen} />
      <List
        component="nav"
        sx={{
          my: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MainListItems setMobileOpen={setMobileOpen} activeTab={activeTab} />
        <SecondaryListItems
          openSupportPopover={open}
          handleClick={handleClick}
          openMobileSupport={openMobileSupport}
          setOpenMobileSupport={setOpenMobileSupport}
          setMobileOpen={setMobileOpen}
          activeTab={activeTab}
        />
      </List>
      <SupportPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </>
  );
}

export default Menu;

import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const Card = styled(Box)(() => ({
  width: "100%",
  maxWidth: "352px",
  textAlign: "left",
  background: "#FFFFFF",
  border: "1px solid rgba(80, 80, 80, 0.1)",
  borderRadius: "4px",
}));

function OnboardingCard({ children, ...props }) {
  return <Card {...props}>{children}</Card>;
}

export default OnboardingCard;

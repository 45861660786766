import React from "react";
import { AppBarContext } from "../context/AppBarProvider";
import Container from "@mui/material/Container";
import Page from "../components/Page";
import { Box, Grid, Typography, Tabs, Tab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import BasicView from "../components/CommandsTabView/BasicsView";
import IconsShortcutsView from "../components/CommandsTabView/IconsShortcutsView";
import ActionsView from "../components/CommandsTabView/ActionsView";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { EDIT_COMMAND, MOVE_COMMAND } from "../graphql/mutation/command";
import { GET_COMMAND } from "../graphql/query/command";
import { moveUpdateCommandCache } from "../graphql/helpers";
import { useSnackbar } from "notistack";
import { GET_CATEGORIES_WITH_COMMANDS } from "../graphql/query/category";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { breadcrumbRoutes } from "../components/Breadcrumb/breadcrumbRoutes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditCommand = () => {
  const navigate = useNavigate();

  const client = useApolloClient();
  const { id, commandId } = useParams();

  const { setTitle, setText } = React.useContext(AppBarContext);
  const [value, setValue] = React.useState(0);
  const [commandValues, setCommandValues] = React.useState({});

  const [editCommand] = useMutation(EDIT_COMMAND);
  const [moveCommand] = useMutation(MOVE_COMMAND);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setText("");
    setTitle("Edit command");
  }, [setTitle, setText]);

  const { data: categoryData } = useQuery(GET_CATEGORIES_WITH_COMMANDS, {
    variables: {
      projectId: id,
    },
  });

  const { data: commandData } = useQuery(GET_COMMAND, {
    variables: {
      getCommandId: commandId,
    },
  });

  const categories = categoryData?.getCategoriesWithCommands || [];

  const command = commandData?.getCommand;

  const oldCategoryOfCommand = categories?.find((category) =>
    category?.commands?.some((c) => c.id === command?.id)
  );

  React.useEffect(() => {
    const ACTION_TYPES = [
      {
        label: "Callback",
        id: "CALLBACK",
      },
      { label: "Link", id: "LINK" },
      {
        label: "Web Hook",
        id: "WEBHOOK",
      },
    ];

    if (command) {
      setCommandValues({
        title: command.title || "",
        description: command.description || "",
        icon: command.icon || "",
        hasIcon: command.hasIcon || false,
        alwaysShow: command.alwaysShow || false,
        isRecommended: command.isRecommended || false,
        shortcut: command.shortcut || [""],
        keywords: command.keywords || [""],
        actionType: {
          id: command.actionType,
          label: ACTION_TYPES.find((action) => action.id === command.actionType)
            .label,
        },
        callback: command.callback || "",
        link: command.link || "",
        openInSameTab: command.openInSameTab || false,
        webhook: command.webhook || "",
        category: oldCategoryOfCommand
          ? {
              id: oldCategoryOfCommand?.id,
              name: oldCategoryOfCommand?.name,
            }
          : { name: "No category", id: "CUSTOM_OPTION" },
      });
    }
  }, [command, oldCategoryOfCommand]);

  if (!command) {
    return null;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditCommand = async () => {
    let errMsg = false;
    if (!commandValues.actionType) {
      setValue(2);
      errMsg = "Action Type";
    } else if (!commandValues.title) {
      setValue(0);
      errMsg = "Title";
    }

    if (errMsg) {
      enqueueSnackbar(`${errMsg} is Required`, {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        autoHideDuration: 3000,
        variant: "error",
      });
      return;
    }

    const customVals = {
      ...commandValues,
      actionType: commandValues.actionType?.id || null,
      alwaysShow: commandValues.alwaysShow || false,
      isRecommended: commandValues.isRecommended || false,
      hasIcon: commandValues.hasIcon || false,
      shortcut: commandValues.shortcut || [""],
      keywords: commandValues.keywords || [""],
    };

    delete customVals.category;

    const { data } = await editCommand({
      variables: {
        editCommandId: commandId,
        data: customVals,
      },
    });

    let newCategId = commandValues?.category?.id;

    if (newCategId === "CUSTOM_OPTION") {
      newCategId = null;
    }

    if (oldCategoryOfCommand || newCategId) {
      if (oldCategoryOfCommand?.id !== newCategId) {
        await moveCommand({
          variables: {
            commandId: commandId,
            categoryId: newCategId || null,
          },
        });

        moveUpdateCommandCache(client, id, {
          editedCommand: data.editCommand,
          newCategoryId: newCategId,
          oldCategoryId: oldCategoryOfCommand?.id,
        });
      }
    }

    enqueueSnackbar("Command is Edited", {
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      autoHideDuration: 3000,
      variant: "success",
    });

    navigate("/projects/" + id);
  };

  return (
    <Page title="Edit Command">
      <Container>
        <Breadcrumb breadcrumbRoutes={breadcrumbRoutes(id).editCommand} />
        <Typography variant="body1" color="#667080" marginBottom="32px">
          Add a command for this project. You can add as many commands as you
          want.
        </Typography>
        <Grid container item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab alignItems="start" label="Basics" {...a11yProps(0)} />
                <Tab label="Icons & shortcuts" {...a11yProps(1)} />
                <Tab label="Actions" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <BasicView
                commandValues={commandValues}
                setCommandValues={setCommandValues}
                handleCreateCommand={handleEditCommand}
                type="edit"
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <IconsShortcutsView
                commandValues={commandValues}
                setCommandValues={setCommandValues}
                handleCreateCommand={handleEditCommand}
                type="edit"
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ActionsView
                commandValues={commandValues}
                setCommandValues={setCommandValues}
                handleCreateCommand={handleEditCommand}
                type="edit"
              />
            </TabPanel>
          </Box>
        </Grid>
      </Container>
    </Page>
  );
};

export default EditCommand;

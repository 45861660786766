import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { CheckIcon } from "../../assets/icons";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { CREATE_CATEGORY } from "../../graphql/mutation/category";

export default function AddCategoryPopup({
  setOpenAddCategory,
  openAddCategory,
  pId,
}) {
  const client = useApolloClient();
  const re = new RegExp("^.{1,25}$");
  const [categoryName, setCategoryName] = React.useState("");
  const [createCategory] = useMutation(CREATE_CATEGORY);
  const handleClose = () => {
    setOpenAddCategory(false);
  };
  const handleCreateCategory = async () => {
    createCategory({
      variables: {
        data: { name: categoryName, projectId: pId },
      },
    }).then(({ data }) => {
      // push new category to cache
      client.cache.modify({
        fields: {
          getCategoriesWithCommands(existingCategories = []) {
            const newCategoryRef = client.cache.writeFragment({
              data: data.createCategory,
              fragment: gql`
                fragment NewCategory on Category {
                  id
                  name
                  commands {
                    id
                    title
                    status
                    lexorankOrder
                  }
                }
              `,
            });
            return [...existingCategories, newCategoryRef];
          },
        },
      });
    });
    setCategoryName("");
    setOpenAddCategory(false);
  };

  return (
    <Dialog
      open={openAddCategory}
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="popUpTitle">
          Enter the name of the category.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          sx={{
            paddingTop: "12px",
          }}
          onChange={(e) => {
            setCategoryName(e.target.value);
          }}
          InputProps={{
            endAdornment: re.test(categoryName) && (
              <InputAdornment position="end">
                <CheckIcon />
              </InputAdornment>
            ),
          }}
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && re.test(categoryName)) {
              ev.preventDefault();
              handleCreateCategory();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Grid container item sx={12} xs={{ alignItems: "center" }}>
          <Grid item xs={6} sx={{ p: "12px" }}>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ p: "12px" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                handleCreateCategory();
              }}
              autoFocus
              disabled={!re.test(categoryName)}
            >
              Create Category
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

import { gql } from "@apollo/client";
import { GET_CATEGORIES_WITH_COMMANDS } from "./query/category";

export const upsert = (
  field,
  comparisonValue,
  newFragment,
  { existingProjects = [], readField }
) => {
  if (
    existingProjects.some((ref) => comparisonValue === readField(field, ref))
  ) {
    return existingProjects;
  }
  return [...existingProjects, newFragment];
};

export const moveUpdateCommandCache = (client, projectId, cacheVars) => {
  const { editedCommand, newCategoryId, oldCategoryId } = cacheVars;

  const data = client.readQuery({
    query: GET_CATEGORIES_WITH_COMMANDS,
    variables: {
      projectId,
    },
  });

  let cacheCategories = data?.getCategoriesWithCommands || [];

  if (newCategoryId) {
    const newChosenCategory = cacheCategories.find(
      (cat) => cat.id === newCategoryId
    );

    const commands = newChosenCategory.commands;

    const newCommands = [...commands, editedCommand];

    // add command to new category's commands

    client.writeQuery({
      query: GET_CATEGORIES_WITH_COMMANDS,
      variables: {
        projectId,
      },
      data: {
        getCategoriesWithCommands: cacheCategories.map((category) =>
          category.id === newCategoryId
            ? { ...category, commands: newCommands }
            : category
        ),
      },
    });

    // also update cache object
    cacheCategories = cacheCategories.map((category) =>
      category.id === newCategoryId
        ? { ...category, commands: newCommands }
        : category
    );
  } else {
    client.cache.modify({
      fields: {
        getUncategorizedCommands(existingCommands = []) {
          const editedCommandRef = client.cache.writeFragment({
            data: editedCommand,
            fragment: gql`
              fragment NewCommand on Command {
                id
                title
                description
                icon
                hasIcon
                alwaysShow
                isRecommended
                shortcut
                keywords
                actionType
                status
                callback
                link
                openInSameTab
                webhook
                useCount
                lexorankOrder
              }
            `,
          });
          return [...existingCommands, editedCommandRef];
        },
      },
    });
  }

  // delete command from old lists
  if (oldCategoryId) {
    // from one category to another
    // from one category to bottom
    if (oldCategoryId !== newCategoryId) {
      client.writeQuery({
        query: GET_CATEGORIES_WITH_COMMANDS,
        variables: {
          projectId,
        },
        data: {
          getCategoriesWithCommands: cacheCategories.map((category) =>
            category.id === oldCategoryId
              ? {
                  ...category,
                  commands: category.commands.filter(
                    (c) => c.id !== editedCommand.id
                  ),
                }
              : category
          ),
        },
      });
    }
  } else {
    // from bottom to one category
    client.cache.modify({
      fields: {
        getUncategorizedCommands(existingCommands = [], { readField }) {
          return existingCommands.filter(
            (commandRef) => editedCommand.id !== readField("id", commandRef)
          );
        },
      },
    });
  }
};

export const createUpdateCommandCache = (client, projectId, cacheVars) => {
  const { newCommand, categoryId } = cacheVars;

  if (categoryId) {
    const data = client.readQuery({
      query: GET_CATEGORIES_WITH_COMMANDS,
      variables: {
        projectId,
      },
    });

    const categories = data?.getCategoriesWithCommands || [];
    const chosenCategory = categories.find((cat) => cat.id === categoryId);

    const commands = chosenCategory.commands;

    const newCommands = [...commands, newCommand];

    client.writeQuery({
      query: GET_CATEGORIES_WITH_COMMANDS,
      variables: {
        projectId,
      },
      data: {
        getCategoriesWithCommands: [
          ...categories.filter((cat) => cat.id !== categoryId),
          {
            ...chosenCategory,
            commands: newCommands,
          },
        ],
      },
    });
  } else {
    client.cache.modify({
      fields: {
        getUncategorizedCommands(existingCommands = []) {
          const newCommandRef = client.cache.writeFragment({
            data: newCommand,
            fragment: gql`
              fragment NewCommand on Command {
                id
                title
                description
                icon
                hasIcon
                alwaysShow
                isRecommended
                shortcut
                keywords
                actionType
                status
                callback
                link
                openInSameTab
                webhook
                useCount
                lexorankOrder
              }
            `,
          });
          return [...existingCommands, newCommandRef];
        },
      },
    });
  }
};

import React from "react";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { CREATE_COMPANY } from "../../../graphql/mutation/company";
import { OnboardingContext } from "../../../context/OnboardingProvider";
import OnboardingCard from "../OnboardingCard";
import OnboardingButton from "../OnboardingButton";

const employeeRole = [
  { label: "Product Management", value: "PRODUCT_MANAGEMENT" },
  { label: "Marketing", value: "MARKETING" },
  { label: "Development", value: "DEVELOPMENT" },
  { label: "Growth", value: "GROWTH" },
  { label: "Executive", value: "EXECUTIVE" },
  { label: "Other", value: "OTHER" },
];

function Step4() {
  const {
    setActiveStep,
    companyName,
    numberOfEmployee,
    roleOfEmployee,
    setRoleOfEmployee,
  } = React.useContext(OnboardingContext);
  const { enqueueSnackbar } = useSnackbar();
  const [createCompany] = useMutation(CREATE_COMPANY);

  const handleCreateCompany = async () => {
    await createCompany({
      variables: {
        data: {
          companyRole: roleOfEmployee,
          employeeCount: numberOfEmployee,
          name: companyName,
        },
      },
      onCompleted: () => {
        setActiveStep(4);
      },
      onError: () => {
        enqueueSnackbar("Something went wrong.", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          autoHideDuration: 3000,
          variant: "error",
        });
      },
    });
  };

  React.useEffect(() => {
    if (!companyName || !numberOfEmployee) {
      setActiveStep(1);
    }
  }, [companyName, numberOfEmployee, setActiveStep]);

  return (
    <OnboardingCard sx={{ padding: "19px 33px 40px" }}>
      <Typography variant="h2" fontSize="18px" color="#303030">
        What best describes your role?
      </Typography>
      <Typography variant="body1" color="#667080">
        We'll help you get started based on your response.
      </Typography>
      <Box marginTop="25px" marginBottom="29px">
        <RadioGroup
          value={roleOfEmployee}
          onChange={(e) => setRoleOfEmployee(e.target.value)}
          sx={{ gap: "8px" }}
        >
          {employeeRole.map((role) => (
            <FormControlLabel
              key={role.value}
              value={role.value}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "18px",
                    },
                  }}
                />
              }
              label={role.label}
              sx={{
                margin: 0,
                transition: "border .25s ease",
                border: "1px solid rgba(86, 103, 137, 0.26)",
                borderRadius: "8px",
                ".MuiFormControlLabel-label": {
                  color: "#101828",
                },
                "&:hover": {
                  borderColor: "#3069fe",
                  ".MuiFormControlLabel-label": {
                    color: "#000000",
                  },
                },
              }}
            />
          ))}
        </RadioGroup>
      </Box>
      <Box textAlign="center">
        <OnboardingButton
          disabled={!roleOfEmployee}
          handleClick={handleCreateCompany}
        >
          Next
        </OnboardingButton>
      </Box>
    </OnboardingCard>
  );
}

export default Step4;

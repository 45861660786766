import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useInitalKeyPress } from "../../utils/useKeyPress";

export default function CreateShortcutPopup({
  open,
  setOpen,
  setHotkeyArr,
  hotkeyArr,
  commandValues,
  setCommandValues,
}) {
  useInitalKeyPress(setHotkeyArr, hotkeyArr);

  const handleClose = () => {
    setHotkeyArr([]);
    setOpen(false);
  };

  const handleCreateProject = () => {
    commandValues["shortcut"] = hotkeyArr;
    setCommandValues(commandValues);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">Create Shortcut</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            disabled
            value={hotkeyArr.join(" + ")}
            sx={{
              marginTop: "21px",
              marginBottom: "31px",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleCreateProject()}
                autoFocus
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

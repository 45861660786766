import React, { createContext } from "react";

export const AppBarContext = createContext();

function AppBarProvider({ children }) {
  const [text, setText] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBarContext.Provider
      value={{
        text,
        title,
        mobileOpen,
        setText,
        setTitle,
        setMobileOpen,
        handleDrawerToggle,
      }}
    >
      {children}
    </AppBarContext.Provider>
  );
}

export default AppBarProvider;

import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  styled,
} from "@mui/material";
import { CHANGE_PASSWORD } from "../../graphql/mutation/auth";
import { useMutation } from "@apollo/client";
import { UserContext } from "../../context/UserProvider";

const HeaderBox = styled(Box)(({ theme }) => ({
  background: "#eef1ff",
  border: "1px solid rgba(80, 80, 80, 0.1)",
  borderRadius: "2px",
  padding: "20px 29px",
  margin: "21px 0",
  [theme.breakpoints.down("md")]: {
    padding: "16px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 12px",
  },
}));

function PasswordTabPanel() {
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const { myProfile } = React.useContext(UserContext);

  const isGoogleSub = myProfile?.googleSub ? true : false;

  return (
    <>
      {isGoogleSub && (
        <HeaderBox>
          <Typography variant="body2" fontSize="14px" color="#1E293B">
            Since you have logged in via SSO, you cannot reset or modify your
            password.
          </Typography>
        </HeaderBox>
      )}
      <Formik
        initialValues={{
          password: "",
          newPassword: "",
          newPasswordConfirmation: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required("Password is required"),
          newPassword: Yup.string()
            .required("New password is required")
            // must be atleast 8 characters long
            // must have atleast 1 uppercase
            // must have atleast 1 number
            // must have atleast 1 special character
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_])(?=.{8,})/
            ),
          newPasswordConfirmation: Yup.string()
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
            .required("New password confirmation is required"),
        })}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          if (!isGoogleSub) {
            changePassword({
              variables: {
                oldPassword: values.password,
                newPassword: values.newPassword,
              },
            }).then((res) => {
              console.log(res);
              setStatus({ success: true });
              setSubmitting(false);
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Box
            sx={{ mt: 4, maxWidth: { sm: "412px", md: "469px", lg: "491px" } }}
          >
            <form noValidate onSubmit={handleSubmit}>
              <Grid container marginBottom="20px">
                <Grid
                  item
                  xs={12}
                  sm={5}
                  display="flex"
                  alignItems="start"
                  justifyContent={{ xs: "start", sm: "end" }}
                >
                  <Typography
                    variant="body1"
                    marginRight={{ xs: "24px", md: "48px", lg: "57px" }}
                    marginTop="10px"
                  >
                    Current password
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    disabled={isGoogleSub}
                    margin="none"
                    error={Boolean(touched.password && errors.password)}
                    sx={{
                      width: "100%",
                      maxWidth: "296px",
                    }}
                    helperText={touched.password && errors.password}
                    name="password"
                    placeholder="Enter your password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    id="password"
                    autoComplete="current-password"
                    inputProps={{
                      style: {
                        padding: "12px 16px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  display="flex"
                  alignItems="start"
                  justifyContent={{ xs: "start", sm: "end" }}
                >
                  <Typography
                    variant="body1"
                    marginRight={{ xs: "24px", md: "48px", lg: "57px" }}
                    marginTop="10px"
                  >
                    New password
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    disabled={isGoogleSub}
                    margin="none"
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    sx={{
                      width: "100%",
                      maxWidth: "296px",
                    }}
                    type="password"
                    name="newPassword"
                    placeholder="Enter your new password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    id="newPassword"
                    autoComplete="current-password"
                    inputProps={{
                      style: {
                        padding: "12px 16px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="end" marginBottom="20px">
                <Grid item xs={12} sm={7}>
                  {!(touched.newPassword && errors.newPassword) ? (
                    <Typography
                      variant="body1"
                      color="#666666"
                      fontSize="12px"
                      marginX="14px"
                      marginTop="4px"
                      maxWidth="268px"
                    >
                      New password must contain at least 8 characters and must
                      have at least one uppercase letter, one number and one
                      symbol.
                    </Typography>
                  ) : (
                    <Box margin="0 14px" maxWidth="268px">
                      <Typography
                        variant="body1"
                        color="#db504a"
                        fontSize="12px"
                      >
                        New password must contain:
                      </Typography>
                      <ul style={{ margin: "4px 0" }}>
                        <li>
                          <Typography
                            variant="body1"
                            color="#666666"
                            fontSize="12px"
                          >
                            At least 8 characters
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="body1"
                            color="#666666"
                            fontSize="12px"
                          >
                            Must have at least one uppercase letter
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="body1"
                            color="#666666"
                            fontSize="12px"
                          >
                            Must have at least one number
                          </Typography>
                        </li>
                        <li>
                          <Typography
                            variant="body1"
                            color="#666666"
                            fontSize="12px"
                          >
                            Must have at least one symbol
                          </Typography>
                        </li>
                      </ul>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  display="flex"
                  alignItems="start"
                  justifyContent={{ xs: "start", sm: "end" }}
                >
                  <Typography
                    variant="body1"
                    marginRight={{ xs: "24px", md: "48px", lg: "57px" }}
                    marginTop="10px"
                  >
                    Confirm new password
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    disabled={isGoogleSub}
                    margin="none"
                    name="newPasswordConfirmation"
                    type="password"
                    error={Boolean(
                      touched.newPasswordConfirmation &&
                        errors.newPasswordConfirmation
                    )}
                    sx={{
                      width: "100%",
                      maxWidth: "296px",
                    }}
                    helperText={
                      touched.newPasswordConfirmation &&
                      errors.newPasswordConfirmation
                    }
                    placeholder="Confirm your new password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPasswordConfirmation}
                    id="newPasswordConfirmation"
                    autoComplete="new-password"
                    inputProps={{
                      style: {
                        padding: "12px 16px",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting || isGoogleSub}
                sx={{
                  width: "120px",
                  marginTop: "20px",
                  color: "#ffffff",
                  backgroundColor: "#4C7DE7",
                  "&:hover": {
                    backgroundColor: "#3d6dd4",
                  },
                }}
              >
                Update
              </Button>
            </form>
          </Box>
        )}
      </Formik>
    </>
  );
}

export default PasswordTabPanel;

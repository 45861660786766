import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVerticalIcon } from "../../assets/icons";
import DeleteCommand from "../CommandPopups/DeleteCommandPopup";
import MoveCommand from "../CommandPopups/MoveCommandPopup";
import { Link, useParams } from "react-router-dom";
import ActivateCommand from "../CommandPopups/ActivateCommandPopup";
import DeactivateCommand from "../CommandPopups/DeactivateCommandPopup";
import { CREATE_COMMAND, MOVE_COMMAND } from "../../graphql/mutation/command";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  createUpdateCommandCache,
  moveUpdateCommandCache,
} from "../../graphql/helpers";

const ITEM_HEIGHT = 48;

export default function CommandMenu({ command, categoryId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedMenu, setSelectedMenu] = React.useState("");
  const [createCommand] = useMutation(CREATE_COMMAND);
  const [moveCommand] = useMutation(MOVE_COMMAND);
  const client = useApolloClient();
  const { id: projectId } = useParams();

  React.useEffect(() => {
    if (command.status === "ACTIVE") {
      setOptions(["Edit", "Duplicate", "Make draft", "Move to", "Delete"]);
    }

    if (command.status === "DRAFT") {
      setOptions(["Edit", "Duplicate", "Make active", "Move to", "Delete"]);
    }
  }, [command.status]);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const popupOpener = (event, option) => {
    event.stopPropagation();
    setSelectedMenu(option);
    setPopupOpen(true);
  };

  const duplicateCommand = async () => {
    const { id, lexorankOrder, useCount, __typename, ...duplicatedCommand } =
      command;

    const { data } = await createCommand({
      variables: {
        projectId,
        data: duplicatedCommand,
      },
    });

    if (categoryId) {
      await moveCommand({
        variables: {
          commandId: data.createCommand.id,
          categoryId: categoryId,
        },
      });

      moveUpdateCommandCache(client, projectId, {
        editedCommand: data.createCommand,
        newCategoryId: categoryId,
        oldCategoryId: null,
      });
    }

    createUpdateCommandCache(client, id, {
      newCommand: data.createCommand,
      categoryId: duplicatedCommand.category?.id || null,
    });

    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVerticalIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) =>
          option === "Edit" ? (
            <MenuItem
              component={Link}
              to={`edit-command/${command.id}`}
              key={option}
            >
              {option}
            </MenuItem>
          ) : option === "Duplicate" ? (
            <MenuItem onClick={duplicateCommand} key={option}>
              {option}
            </MenuItem>
          ) : (
            <MenuItem key={option} onClick={(e) => popupOpener(e, option)}>
              {option}
            </MenuItem>
          )
        )}
      </Menu>
      {selectedMenu === "Make active" && (
        <ActivateCommand
          handleClosePopup={handleClose}
          open={popupOpen}
          setSelectedMenu={setSelectedMenu}
          setOpen={setPopupOpen}
          cName={command.title}
          cId={command.id}
        />
      )}
      {selectedMenu === "Make draft" && (
        <DeactivateCommand
          handleClosePopup={handleClose}
          open={popupOpen}
          setSelectedMenu={setSelectedMenu}
          setOpen={setPopupOpen}
          cName={command.title}
          cId={command.id}
        />
      )}
      {selectedMenu === "Delete" && (
        <DeleteCommand
          handleClosePopup={handleClose}
          open={popupOpen}
          setSelectedMenu={setSelectedMenu}
          setOpen={setPopupOpen}
          cName={command.title}
          cId={command.id}
          categoryId={categoryId}
        />
      )}
      {selectedMenu === "Move to" && (
        <MoveCommand
          handleClosePopup={handleClose}
          open={popupOpen}
          setSelectedMenu={setSelectedMenu}
          setOpen={setPopupOpen}
          cName={command.title}
          cId={command.id}
        />
      )}
    </div>
  );
}

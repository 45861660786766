import * as React from "react";
import { Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DateTime } from "luxon";

export default function TabList({ message, createdAt, projectName }) {
  const dateFromNow = DateTime.fromISO(createdAt).toFormat(
    "dd LLLL yyyy  HH:mm"
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container item alignItems="center" sx={{ paddingY: "10px" }}>
        <Grid item xs={4} align="center">
          <Typography align="left">{message}</Typography>
        </Grid>
        <Grid item xs={4} align="center">
          <Typography>{dateFromNow}</Typography>
        </Grid>
        <Grid item xs={4} align="center">
          <Typography>{projectName}</Typography>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
}

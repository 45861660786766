import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { EDIT_PROFILE } from "../../../graphql/mutation/auth";
import { OnboardingContext } from "../../../context/OnboardingProvider";
import OnboardingCard from "../OnboardingCard";
import OnboardingButton from "../OnboardingButton";

function Step6() {
  const {
    setProjectName,
    setCompanyName,
    setNumberOfEmployee,
    setRoleOfEmployee,
  } = React.useContext(OnboardingContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [editProfile] = useMutation(EDIT_PROFILE);

  const handleCompleteOnboarding = async () => {
    await editProfile({
      variables: {
        data: { fullyCompletedOnboarding: true },
      },
      onCompleted: () => {
        setProjectName("");
        setCompanyName("");
        setNumberOfEmployee("");
        setRoleOfEmployee("");
        navigate("/home");
      },
      onError: () => {
        enqueueSnackbar("Something went wrong.", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          autoHideDuration: 3000,
          variant: "error",
        });
      },
    });
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <OnboardingCard sx={{ padding: "24px 39px 40px" }}>
        <Typography
          variant="h2"
          fontSize="18px"
          color="#303030"
          marginBottom="20px"
        >
          Almost there!
        </Typography>
        <Typography variant="body1" color="#667080" marginBottom="12px">
          Your project is now ready to add commands and categories. Please head
          over to projects menu and start adding.
        </Typography>
        <Typography variant="body1" color="#667080">
          When you are done, set the project active and initialize the Magny JS
          library in your app.
        </Typography>
        <Box marginTop="24px" textAlign="center">
          <OnboardingButton handleClick={handleCompleteOnboarding}>
            Sweet!
          </OnboardingButton>
        </Box>
      </OnboardingCard>
      <FormControlLabel
        sx={{
          marginTop: "32px",
          ".MuiFormControlLabel-label": { color: "#667080" },
        }}
        control={
          <Checkbox
            defaultChecked
            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
          />
        }
        label="Stay up to date by subscribing to our emails."
      />
    </Box>
  );
}

export default Step6;

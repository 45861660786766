import { gql } from "@apollo/client";

export const REORDER_COMMAND = gql`
  mutation reorderCommand(
    $selectedCommandId: ID!
    $priorCommandId: ID
    $ulteriorCommandId: ID
  ) {
    reorderCommand(
      selectedCommandId: $selectedCommandId
      priorCommandId: $priorCommandId
      ulteriorCommandId: $ulteriorCommandId
    ) {
      id
      title
      description
      icon
      lexorankOrder
    }
  }
`;

export const REORDER_CATEGORY = gql`
  mutation reorderCategory(
    $selectedCategoryId: ID!
    $priorCategoryId: ID
    $ulteriorCategoryId: ID
  ) {
    reorderCategory(
      selectedCategoryId: $selectedCategoryId
      priorCategoryId: $priorCategoryId
      ulteriorCategoryId: $ulteriorCategoryId
    ) {
      id
      name
      lexorankOrder
    }
  }
`;

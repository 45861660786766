import React, { useMemo } from "react";
import { AppBarContext } from "../context/AppBarProvider";
import Page from "../components/Page";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import Header from "../components/Header";
import TabView from "../components/FeedbacksTabView/TabView";
import { UserContext } from "../context/UserProvider";
import { useQuery } from "@apollo/client";
import { MY_PROJECTS_WITH_FEEDBACKS } from "../graphql/query/project";
import { breadcrumbRoutes } from "../components/Breadcrumb/breadcrumbRoutes";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

function FeedbacksTable({ myProfile, feedbacks }) {
  return (
    <>
      {!myProfile?.hasSeenBanners?.feedbacks && (
        <Header
          title="All feedbacks your users submit will appear here. 👋"
          description="A feedback request is shown when a user cannot find what he/she is looking for. In this case, the user can submit a fedback and it will be visible here. Feel free to go over all feedbacks and improve your application as necessary."
          pageName="feedbacks"
        />
      )}
      <Grid container>
        <TabView feedbacks={feedbacks} />
      </Grid>
    </>
  );
}

function FeedbacksHelp() {
  return (
    <>
      <Grid
        container
        spacing={{ xs: "72px", lg: "16px" }}
        sx={{ marginTop: "72px" }}
      >
        <Grid item xs={12} md={6} lg={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="../../feedbacksLeftHero.svg"
              alt="feedbacks"
              style={{ width: "100%", maxWidth: "340px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" color="#4C7DE7" sx={{ mb: 2 }}>
              USER FEEDBACKS
            </Typography>
            <Typography variant="feedbacksTitle">
              Get end user feedbacks to improve your search bar experience
            </Typography>
            <Typography variant="feedbacks">
              Sometimes your users may not find a search item they are looking
              for. We call it a "deadend".
            </Typography>
            <Typography variant="feedbacks">
              In this case, they are presented with an option to send their
              search suggestions. This is where you can find all of your user's
              feedbacks, and act accordingly if you find them useful.
            </Typography>
            <Button variant="contained" sx={{ maxWidth: "400px" }}>
              Learn how to install Magny on your app
            </Button>
            <Box
              component={Link}
              to="/home"
              sx={{ display: "flex", alignItems: "end", gap: "8px", mt: 2 }}
            >
              <Typography variant="body1" color="#667080">
                Get help installing Magny instead? Contact us
              </Typography>
              <EastIcon fontSize="small" color="#667080" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const Feedbacks = () => {
  const { setTitle, setText } = React.useContext(AppBarContext);
  const { myProfile } = React.useContext(UserContext);

  React.useEffect(() => {
    setText("");
    setTitle("Feedbacks");
  }, [setTitle, setText]);

  const { data, loading } = useQuery(MY_PROJECTS_WITH_FEEDBACKS);
  const projects = data?.myProjects;

  const feedbacks = useMemo(() => {
    if (!projects) return [];

    const fbs = [];
    projects
      .forEach((project) => {
        project.feedbacks.forEach((feedback) => {
          fbs.push({
            ...feedback,
            projectName: project.name,
          });
        });
      })
      ?.sort((a, b) => b.createdAt - a.createdAt);

    return fbs;
  }, [projects]);

  if (loading) return null;

  return (
    <Page title="Feedbacks">
      <Container>
        <Breadcrumb breadcrumbRoutes={breadcrumbRoutes().feedbacks} />
        {feedbacks.length === 0 ? (
          <FeedbacksHelp />
        ) : (
          <FeedbacksTable myProfile={myProfile} feedbacks={feedbacks} />
        )}
      </Container>
    </Page>
  );
};
export default Feedbacks;

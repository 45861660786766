import { gql } from "@apollo/client";

export const AUTHENTICATE_USER = gql`
  mutation authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      token
      user {
        id
        role
      }
    }
  }
`;

export const GOOGLE_OAUTH = gql`
  mutation authenticateGoogle($googleAccessToken: String!) {
    authenticate: authenticateGoogle(googleAccessToken: $googleAccessToken) {
      token
      user {
        id
        role
      }
    }
  }
`;

export const SIGNUP_USER = gql`
  mutation SignupUser($data: UserCreateInput!) {
    signupUser(data: $data) {
      token
      user {
        id
        email
        role
        firstName
        lastName
      }
    }
  }
`;
export const EDIT_PROFILE = gql`
  mutation EditProfile($data: UserEditInput!) {
    editProfile(data: $data) {
      id
      email
      completedOnboarding
      firstName
      lastName
      fullName
    }
  }
`;

export const DELETE_ME = gql`
  mutation deleteMyAccount {
    deleteMyAccount {
      id
    }
  }
`;

export const SEE_PAGE_TOP_BANNER = gql`
  mutation seeTopPageBanner($pageSlug: PagesWithTopBannerInput!) {
    seePageTopBanner(pageSlug: $pageSlug) {
      hasSeenBanners {
        logs
        feedbacks
        users
        projects
        analytics
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePasswordWithoutCode(
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePasswordWithoutCode(
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`;

import { Grid, IconButton } from "@mui/material";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DnDIcon } from "../../assets/icons";
import DnDAccordion from "./DnDAccordion";

const DroppableAndDraggable = ({ category, index }) => {
  return (
    <Droppable droppableId={category.id} type="CATEGORY" direction="vertical">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <Draggable draggableId={category.id} index={index}>
            {(dragProvided, snapshot) => {
              return (
                <div
                  ref={dragProvided.innerRef}
                  snapshot={snapshot}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                >
                  <Grid container alignItems="center" xs={12}>
                    <Grid item xs={0.5}>
                      <IconButton disabled>
                        <DnDIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={11.5}>
                      <DnDAccordion category={category} />
                    </Grid>
                  </Grid>
                </div>
              );
            }}
          </Draggable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableAndDraggable;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PasswordIcon, TeamsIcon } from "../assets/icons";
import { AppBarContext } from "../context/AppBarProvider";
import { UserContext } from "../context/UserProvider";
import Search from "./Search";
import authService from "../services/authService";
import { ReactComponent as MyProfileIcon } from "../assets/images/profileIcon.svg";
import { ReactComponent as PlansIcon } from "../assets/images/plansIcon.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/logoutIcon.svg";

function Appbar() {
  const { title, text, handleDrawerToggle } = React.useContext(AppBarContext);
  const { myProfile } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const client = useApolloClient();

  const navigate = useNavigate();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    authService.logout(client);
    navigate("/");
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginY: { xs: "12px!important", md: "16px!important" },
        minHeight: "40px",
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { md: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      {text && (
        <Typography
          component="h1"
          variant="body1"
          flexShrink="1"
          noWrap
          color="rgba(0, 0, 0, 0.54)"
        >
          {text}
        </Typography>
      )}
      {title && (
        <Typography variant="h1" flexShrink="1" noWrap color="#4C7DE7">
          {title}
        </Typography>
      )}
      <Box display="flex" justifyContent="end" flexGrow={1} flexShrink={0}>
        <Box
          flexGrow={1}
          justifyContent="end"
          alignItems="center"
          marginLeft="20px"
          gap="20px"
          display={{ xs: "none", md: "flex" }}
        >
          <Search
            placeholder="Search for actions (⌘ + k)"
            width="100%"
            maxWidth="198px"
          />
          <Button
            component={Link}
            to="/profile"
            state={{ pNumber: 3 }}
            variant="contained"
            sx={{
              padding: "0px 24px",
              fontSize: "12px",
              fontWeight: "500",
              height: "30px",
              color: "#FFFFFF",
              background: "#5EBE31",
              borderRadius: "5px",
              border: "1px solid #5AAA34",
              "&:hover": {
                color: "#FFFFFF",
                background: "#479d1e",
              },
            }}
          >
            Upgrade
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            color="inherit"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            endIcon={
              !isMenuOpen ? (
                <KeyboardArrowDownIcon sx={{ color: "#344054" }} />
              ) : (
                <KeyboardArrowUpIcon sx={{ color: "#344054" }} />
              )
            }
            sx={{ marginLeft: "12px" }}
          >
            <Typography
              component="h1"
              flexGrow="1"
              noWrap
              fontSize="14px"
              fontWeight="500"
              lineHeight="22px"
              textTransform="capitalize"
              color="#475569"
            >
              {myProfile?.firstName}
            </Typography>
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                width: 213,
              },
            }}
          >
            <MenuItem
              component={Link}
              to="/profile"
              state={{ pNumber: 0 }}
              onClick={handleMenuClose}
              sx={{ gap: "12px" }}
            >
              <MyProfileIcon />
              My profile
            </MenuItem>
            <MenuItem
              component={Link}
              to="/profile"
              state={{ pNumber: 1 }}
              onClick={handleMenuClose}
              sx={{ gap: "12px" }}
            >
              <PasswordIcon /> Password
            </MenuItem>
            <MenuItem
              component={Link}
              to="/profile"
              state={{ pNumber: 2 }}
              onClick={handleMenuClose}
              sx={{ gap: "12px" }}
            >
              <TeamsIcon /> Teams
            </MenuItem>
            <MenuItem
              component={Link}
              to="/profile"
              state={{ pNumber: 3 }}
              onClick={handleMenuClose}
              sx={{ gap: "12px" }}
            >
              <PlansIcon /> Plans
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleLogout}
              sx={{ color: "#DB504A", gap: "12px" }}
            >
              <LogoutIcon />
              Log out
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Container>
  );
}

export default Appbar;

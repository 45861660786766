import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { MOVE_COMMAND } from "../../graphql/mutation/command";
import { GET_COMMAND } from "../../graphql/query/command";
import { useParams } from "react-router-dom";
import { GET_CATEGORIES_WITH_COMMANDS } from "../../graphql/query/category";
import { moveUpdateCommandCache } from "../../graphql/helpers";

export default function MoveCommand({
  handleClosePopup,
  open,
  setOpen,
  setSelectedMenu,
  cName,
  cId,
}) {
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const [moveCommand] = useMutation(MOVE_COMMAND, {
    onCompleted: (data) => {
      enqueueSnackbar("Command has been successfully moved", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        autoHideDuration: 3000,
        variant: "success",
      });
    },
  });

  const { data: categoryData } = useQuery(GET_CATEGORIES_WITH_COMMANDS, {
    variables: {
      projectId: id,
    },
  });
  const { data: commandData } = useQuery(GET_COMMAND, {
    variables: {
      getCommandId: cId,
    },
  });

  const categories = categoryData?.getCategoriesWithCommands || [];
  const options = [
    { name: "No category", type: "CUSTOM_OPTION" },
    ...categories,
  ];

  const command = commandData?.getCommand;

  if (!command) {
    return null;
  }

  const oldCategoryOfCommand = categories?.find((category) =>
    category?.commands?.some((c) => c.id === command.id)
  );

  const handleChange = (value) => {
    if (value.type === "CUSTOM_OPTION" && value.name === "No category") {
      return setSelectedCategory("");
    }

    const innerSelectedCategory = categories.find(
      (category) => category.id === value.id
    );

    setSelectedCategory(innerSelectedCategory);
  };

  const handleMove = async () => {
    if (oldCategoryOfCommand || selectedCategory?.id) {
      if (oldCategoryOfCommand?.id !== selectedCategory?.id) {
        await moveCommand({
          variables: {
            commandId: cId,
            categoryId: selectedCategory?.id || null,
          },
        });

        moveUpdateCommandCache(client, id, {
          editedCommand: command,
          newCategoryId: selectedCategory?.id,
          oldCategoryId: oldCategoryOfCommand?.id,
        });
      }
    }
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };

  const handleClose = () => {
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">
            Move "{cName}" to a new category?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: "100%", mt: 2 }}>
            <Autocomplete
              id="category"
              name="category"
              options={options}
              getOptionLabel={(option) => option.name}
              defaultValue={options.find(
                (category) => category.id === oldCategoryOfCommand?.id
              ) || options[0]}
              onChange={(e, value) => handleChange(value || options[0])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Category"
                  placeholder="No category"
                  size="medium"
                />
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleMove();
                }}
                autoFocus
              >
                Move
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Tabs, Tab, Typography } from "@mui/material";
import DeadendsTabList from "./DeadendsTabList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DeadendsTabView() {
  const [value, setValue] = React.useState(0);
  const isPremium = false;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Deadends" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Grid
        container
        item
        alignItems="center"
        sx={{
          height: "55px",
          marginTop: "14px",
          marginBottom: "8px",
          backgroundColor: "#f0f0f0",
          border: "1px solid rgba(80, 80, 80, 0.1)",
        }}
      >
        <Grid item xs={6} align="center">
          <Typography align="left" paddingX={2}>
            Keyword
          </Typography>
        </Grid>
        <Grid item xs={6} align="center">
          <Typography align="left" paddingX={2}>
            Number of searches
          </Typography>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <DeadendsTabList keyword="Settings" value={isPremium ? "189" : "-"} />
        <DeadendsTabList keyword="Teams" value={isPremium ? "12" : "-"} />
        <DeadendsTabList keyword="Management" value={isPremium ? "73" : "-"} />
      </TabPanel>
    </Box>
  );
}

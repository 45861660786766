import React from "react";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";

function ProjectStatus({ status }) {
  if (status === "ACTIVE") {
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Chip
          label="Active"
          avatar={
            <div
              style={{
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                background: "#12B76A",
              }}
            />
          }
          fontSize="12px"
          size="small"
          sx={{
            background: "#CFFFE2",
            padding: "4px 4px",
          }}
        />
      </Box>
    );
  }
  if (status === "DRAFT") {
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Chip
          label="Draft"
          avatar={
            <div
              style={{
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                background: "#D6AB00",
              }}
            />
          }
          fontSize="12px"
          size="small"
          sx={{
            background: "#FFEC9E",
            padding: "4px 4px",
          }}
        />
      </Box>
    );
  }

  return "-";
}

export default ProjectStatus;

import * as React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { EmailIcon } from "../../assets/icons";
import { useSnackbar } from "notistack";
import { INVITE_MEMBERS } from "../../graphql/mutation/company";
import { MY_COMPANY } from "../../graphql/query/company";
import { string } from "yup";

export default function InviteMemberPopup({ open, setOpen }) {
  const { enqueueSnackbar } = useSnackbar();
  const [inviteMembers] = useMutation(INVITE_MEMBERS);
  const [email, setEmail] = React.useState("");
  const [valid, setValid] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");

  const handleChange = (e) => {
    const value = e.currentTarget.value;
    setEmail(value);
    setValid(string().email().isValidSync(value));
  };

  const handleClose = () => {
    setEmail("");
    setEmailError("");
    setOpen(false);
    setValid(false);
  };

  const handleInviteMember = async () => {
    if (valid) {
      await inviteMembers({
        variables: { emails: [email] },
        refetchQueries: [MY_COMPANY],
        onCompleted: () => {
          enqueueSnackbar("Member has been successfully invited", {
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            autoHideDuration: 3000,
            variant: "success",
          });
        },
        onError: () => {
          enqueueSnackbar("Something went wrong.", {
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            autoHideDuration: 3000,
            variant: "error",
          });
        },
      });
      handleClose();
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">Invite new team member</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="popUpText" fontSize="12px">
            When you add a new team member, they will get access to all
            projects.
          </Typography>
          <TextField
            autoFocus
            fullWidth
            placeholder="example@magny.com"
            sx={{
              paddingTop: "12px",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                handleInviteMember();
              }
            }}
            inputProps={{
              style: {
                padding: "12px 16px 12px 4px",
              },
            }}
            helperText={emailError && emailError}
            error={emailError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleInviteMember();
                }}
              >
                Send Invite
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  InviteMemberLogo,
  CreateNewProjectLogo,
  UpgradePlanLogo,
} from "../assets/icons/index";

const QuickAccess = () => {
  return (
    <Grid item>
      <Typography variant="h1" marginBottom="12px">Quick Access</Typography>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          border: "1px solid rgba(80, 80, 80, 0.1)",
          borderRadius: "2px",
          height: "100%",
          p: "14px 20px",
          background: "#fff",
        }}
        gap="12px"
      >
        <Grid
          container
          gap="14px"
          alignItems="center"
          component={Link}
          to="/projects"
          sx={{ textDecoration: "none" }}
        >
          <CreateNewProjectLogo />
          <Typography variant="body2" color="#1E293B">
            Create a new project
          </Typography>
        </Grid>

        <Grid
          container
          gap="14px"
          alignItems="center"
          component={Link}
          to="/profile"
          state={{ pNumber: 2 }}
          sx={{ textDecoration: "none" }}
        >
          <InviteMemberLogo />
          <Typography variant="body2" color="#1E293B">
            Invite new team member
          </Typography>
        </Grid>
        <Grid
          container
          gap="14px"
          alignItems="center"
          component={Link}
          to="/profile"
          sx={{ textDecoration: "none" }}
          state={{ pNumber: 3 }}
        >
          <UpgradePlanLogo />
          <Typography variant="body2" color="#1E293B">
            Upgrade your plan
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};

export default QuickAccess;

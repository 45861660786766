import React from "react";
import { AppBarContext } from "../context/AppBarProvider";
import Page from "../components/Page";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import Header from "../components/Header";
import TabView from "../components/UsersTabView/TabView";
import { UserContext } from "../context/UserProvider";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { breadcrumbRoutes } from "../components/Breadcrumb/breadcrumbRoutes";

function UsersTable({ myProfile }) {
  return (
    <Container>
      {!myProfile?.hasSeenBanners?.users && (
        <Header
          title="View all your users here. 🤓"
          description="In this table you can view all of your users (hashed format - no personally identifiable information is collected), and how many times each user has used Magny. All times are in your timezone."
          pageName="users"
        />
      )}
      <Grid container>
        <TabView />
      </Grid>
    </Container>
  );
}

function UsersHelp() {
  return (
    <Container>
      <Breadcrumb breadcrumbRoutes={breadcrumbRoutes().audience} />
      <Grid container spacing={{ xs: "72px", lg: "16px" }} sx={{ marginTop: "72px" }}>
        <Grid item xs={12} md={6} lg={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="../../feedbacksLeftHero.svg"
              alt="feedbacks"
              style={{ width: "100%", maxWidth: "340px" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" color="#4C7DE7" sx={{ mb: 2 }}>
              AUDIENCE DATA
            </Typography>
            <Typography variant="feedbacksTitle">
              Get an overview of your users and understand their behaviours
            </Typography>
            <Typography variant="feedbacks">
              As you start implementing your first Magny popup and users begin
              searching in your application, they will appear here. Magny
              doesn’t collect any personally identifiable information but only
              user hashes, together with how they use Magny.
            </Typography>
            <Button
              variant="contained"
              sx={{ marginTop: "24px", maxWidth: "400px" }}
            >
              Learn how to install Magny on your app
            </Button>
            <Box
              component={Link}
              to="/home"
              sx={{ display: "flex", alignItems: "end", gap: "8px", mt: 2 }}
            >
              <Typography variant="body1" color="#667080">
                Get help installing Magny instead? Contact us
              </Typography>
              <EastIcon fontSize="small" color="#667080" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

const Users = () => {
  const { setTitle, setText } = React.useContext(AppBarContext);
  const { myProfile } = React.useContext(UserContext);

  React.useEffect(() => {
    setText("");
    setTitle("Audience");
  }, [setTitle, setText]);

  // TODO: fetch data from API if null show UsersHelp else show UsersTable
  const content = true ? <UsersHelp /> : <UsersTable myProfile={myProfile} />;

  return <Page title="Audience">{content}</Page>;
};
export default Users;

import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { DELETE_INVITE } from "../../graphql/mutation/company";
import { MY_COMPANY } from "../../graphql/query/company";

export default function DeleteMemberPopup({
  open,
  setOpen,
  selectedMemberID,
  setSelectedMemberID,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteInvite] = useMutation(DELETE_INVITE);

  const handleClose = () => {
    setSelectedMemberID("");
    setOpen(false);
  };

  const handleDelete = async () => {
    await deleteInvite({
      variables: { id: selectedMemberID },
      refetchQueries: [MY_COMPANY],
      onCompleted: () => {
        enqueueSnackbar("Account has been successfully deleted", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          autoHideDuration: 3000,
          variant: "warning",
        });
      },
      onError: () => {
        enqueueSnackbar("Something went wrong.", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          autoHideDuration: 3000,
          variant: "error",
        });
      },
    });

    setSelectedMemberID("");
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle" fontWeight="500">
            Really delete this account?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="popUpText" fontSize="12px">
              If you delete your account, you will no longer be able to sign in,
              and all of your data will be deleted. Deleting your account is
              permanent and non-recoverable action.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0px 24px 24px 24px" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              color: "rgba(21, 25, 32, 0.5)",
              border: "1px solid rgba(86, 103, 137, 0.26)",
              borderRadius: "8px",
              "&:hover": {
                borderColor: "rgba(21, 25, 32, 0.5)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            variant="contained"
            sx={{
              color: "#ffffff",
              backgroundColor: "#DB504A",
              "&:hover": {
                backgroundColor: "#C43F39",
              },
            }}
          >
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

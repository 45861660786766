import { gql } from "@apollo/client";

export const GET_CATEGORIES_WITH_COMMANDS = gql`
  query getCategoriesWithCommands($projectId: ID!) {
    getCategoriesWithCommands(projectId: $projectId) {
      id
      name
      commands {
        id
        title
        description
        icon
        hasIcon
        alwaysShow
        isRecommended
        shortcut
        keywords
        actionType
        status
        callback
        link
        openInSameTab
        webhook
        useCount
        lexorankOrder
      }
    }
  }
`;

export const GET_UNCATEGORIZED_COMMANDS = gql`
  query getUncategorizedCommands($projectId: ID!) {
    getUncategorizedCommands(projectId: $projectId) {
      id
      title
      description
      icon
      hasIcon
      isRecommended
      alwaysShow
      shortcut
      keywords
      actionType
      status
      callback
      link
      openInSameTab
      webhook
      useCount
      lexorankOrder
    }
  }
`;

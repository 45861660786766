import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { EDIT_COMMAND } from "../../graphql/mutation/command";

export default function ActivateCommand({
  handleClosePopup,
  open,
  setOpen,
  setSelectedMenu,
  cName,
  cId,
}) {
  const [editCommand] = useMutation(EDIT_COMMAND);
  const handleClose = () => {
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };
  const handleActivateCommand = async () => {
    await editCommand({
      variables: {
        editCommandId: cId,
        data: {
          status: "ACTIVE",
        },
      },
    });
    setSelectedMenu("");
    handleClosePopup();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">
            Make command {cName} active?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="popUpText" color="#666666">
            Do not forget to initialize the library in your application,
            otherwise the Magny bar won’t be visible to your users.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleActivateCommand()}
                autoFocus
              >
                Make Active
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { gql } from "@apollo/client";

export const MY_PROFILE = gql`
  query MyProfile {
    myProfile {
      id
      email
      role
      firstName
      lastName
      completedOnboarding
      onboardingStep
      hasSeenBanners {
        logs
        feedbacks
        projects
        users
        analytics
      }
      googleSub
    }
  }
`;
export const IS_LOGGED_IN = gql`
  query isLoggedIn {
    isLoggedIn
  }
`;

export const GET_USER_BY_INVITE = gql`
  query getUserByInviteCode($inviteCode: String!) {
    getUserByInviteCode(inviteCode: $inviteCode) {
      id
      email
    }
  }
`;
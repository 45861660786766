import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { CheckIcon } from "../../assets/icons";
import { CREATE_PROJECT } from "../../graphql/mutation/project";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { upsert } from "../../graphql/helpers";
import { useSnackbar } from "notistack";

export default function CreateNewProject({ open, setOpen }) {
  const client = useApolloClient();
  const [projectName, setProjectName] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [createProject] = useMutation(CREATE_PROJECT);
  const re = new RegExp("^.{1,25}$");

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateProject = async () => {
    await createProject({
      variables: {
        data: { name: projectName, status: "DRAFT" },
      },
      onCompleted: (data) => {
        enqueueSnackbar(
          "You have created a new project. Now go ahead and add commands and categories by clicking on Action → View & Edit. ",
          {
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            autoHideDuration: 8000,
            variant: "info",
          }
        );
      },
      onError: (error) => {
        enqueueSnackbar("Something went wrong.", {
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          autoHideDuration: 3000,
          variant: "error",
        });
      },
    }).then(({ data }) => {
      // upsert new project into the cache
      client.cache.modify({
        fields: {
          myProjects(existingProjects = [], { readField }) {
            const newProjectRef = client.cache.writeFragment({
              data: data.createProject,
              fragment: gql`
                fragment NewProject on Project {
                  id
                  name
                  status
                  commandsCount
                }
              `,
            });

            return upsert("id", data.createProject.id, newProjectRef, {
              existingProjects,
              readField,
            });
          },
        },
      });
    });
    setProjectName("");
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="popUpTitle">Create a new project</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            onChange={(e) => {
              setProjectName(e.target.value);
            }}
            sx={{
              paddingTop: "12px",
            }}
            InputProps={{
              endAdornment: re.test(projectName) && (
                <InputAdornment position="end">
                  <CheckIcon />
                </InputAdornment>
              ),
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter" && re.test(projectName)) {
                ev.preventDefault();
                handleCreateProject();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container item sx={12} xs={{ alignItems: "center" }}>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button fullWidth variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: "12px" }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleCreateProject()}
                autoFocus
                disabled={!re.test(projectName)}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

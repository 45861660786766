import React from "react";
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CategoryMenu from "../ProjectDetailView/CategoryMenu";
import DnDCommandCard from "./DnDCommandCard";
import { Draggable, Droppable } from "react-beautiful-dnd";

function CustomAccordionSummary({ category }) {
  return (
    <AccordionSummary sx={{ border: "none" }}>
      <Grid container>
        <Grid item xs={8} display="flex" alignItems="center" gap="13px">
          <Typography variant="body1" color="#575F6E">
            {category.name}
          </Typography>
          {/* make typography look like a blue chip */}
          <Typography
            variant="body1"
            paddingLeft="10px"
            color="#575F6E"
            sx={{
              background: "#E8F0FE",
              borderRadius: "50%",
              padding: "2px 4px",
              fontSize: "10px",
              fontWeight: "bold",
            }}
          >
            {category.commands.length}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="end"
          alignItems="center"
          gap="24px"
        >
          <CategoryMenu cName={category?.name} cId={category?.id} />
        </Grid>
      </Grid>
    </AccordionSummary>
  );
}

function CustomAccordionDetails({ commands, categoryId }) {
  return (
    <Droppable droppableId={categoryId} type="COMMAND" direction="vertical">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <AccordionDetails>
            {commands.length > 0 ? (
              <Box maxWidth="95%" marginLeft="auto">
                {commands.map((command, cmdIdx) => (
                  <div
                    key={command.id}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {/* Categorized Command Draggables */}
                    <Draggable draggableId={command.id} index={cmdIdx}>
                      {(provided, snapshot) => {
                        return (
                          <DnDCommandCard
                            dndProps={{
                              ref: provided.innerRef,
                              snapshot: snapshot,
                              dragProps: provided.draggableProps,
                              handleProps: provided.dragHandleProps,
                            }}
                            command={command}
                            categoryId={categoryId}
                          />
                        );
                      }}
                    </Draggable>
                  </div>
                ))}
              </Box>
            ) : (
              <Grid container marginY="24px" paddingX="42px">
                <Grid item>
                  <Typography
                    variant="body1"
                    fontSize="12px"
                    color="rgb(99, 115, 129)"
                  >
                    Currently zero command under this category
                  </Typography>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function DnDAccordion({ category }) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <Accordion
      onChange={(e, expanded) => {
        setIsExpanded(expanded);
      }}
      sx={{
        backgroundColor: "#f9f9f9",
        borderRadius: "4px",
        border: "1px solid #D6DBE2",
      }}
    >
      <CustomAccordionSummary isExpanded={isExpanded} category={category} />
      <CustomAccordionDetails
        commands={category.commands}
        categoryId={category.id}
      />
    </Accordion>
  );
}

export default DnDAccordion;

import { gql } from "@apollo/client";

export const MY_PROJECTS = gql`
  query MyProjects {
    myProjects {
      id
      name
      status
      commandsCount
      fontFace
      fontColor
      fontUrl
      fontSize
      prompt
    }
  }
`;

export const MY_PROJECTS_WITH_FEEDBACKS = gql`
  query MyProjects {
    myProjects {
      id
      name
      feedbacks {
        id
        createdAt
        message
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query getProject($getProjectId: ID!) {
    getProject(id: $getProjectId) {
      id
      name
      status
      categories {
        id
        name
      }
      commandsCount
      fontFace
      fontColor
      fontUrl
      fontSize
      prompt
    }
  }
`;

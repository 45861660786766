import React from "react";
import { Box, Typography, TextField, Divider } from "@mui/material";
import {
  ArrowWithTailRightIcon,
  ContactSupportIcon,
  CreateFolderIcon,
  DocumentationIcon,
  HiringIcon,
  ProductUpdateIcon,
  ProfileIcon,
  UserIcon,
} from "../../assets/icons";

function ProjectOverview({ fontColorVar, promptValue, fontSizeVar }) {
  return (
    <Box
      maxWidth={{ xs: "100%", md: "580px" }}
      marginLeft={{ xs: "0px", md: "46px" }}
      display="flex"
      flexDirection="column"
      borderRadius="8px"
      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
    >
      <Box position="relative">
        <TextField
          variant="standard"
          fullWidth
          sx={{ border: "none" }}
          placeholder={promptValue ? promptValue : "Search"}
          InputProps={{
            classes: { startAdornment: { margin: "12px" } },
            style: { padding: "16px" },
            disableUnderline: true,
            startAdornment: (
              <svg
                style={{ marginRight: "12px" }}
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
          }}
        />
        <Box
          position="absolute"
          right="0"
          top="50%"
          padding="4px"
          marginRight="16px"
          sx={{
            transform: "translateY(-50%)",
            background: "#F9FAFB",
            border: "1px solid #F2F4F7",
            borderRadius: "4px",
          }}
        >
          ⌘/
        </Box>
      </Box>
      <Divider />
      <Box padding="8px 8px 0 8px">
        <Typography variant="body1" paddingX="4px">
          Recent
        </Typography>
      </Box>
      <Box padding="4px 8px 16px 8px">
        <Box
          borderRadius="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          padding="8px"
          sx={{ "&:hover": { backgroundColor: "#F2F6FF" } }}
        >
          <DocumentationIcon />
          <Typography
            variant="body1"
            sx={{
              color:
                fontColorVar === "Black (Default)" ? "Black" : fontColorVar,
              fontSize:
                fontSizeVar === "Medium (Default)" ? "Medium" : fontSizeVar,
            }}
          >
            Marketing site redesign
          </Typography>
        </Box>
        <Box
          position="relative"
          borderRadius="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          padding="8px"
          sx={{ "&:hover": { backgroundColor: "#F2F6FF" } }}
        >
          <HiringIcon />
          <Typography
            variant="body1"
            sx={{
              color:
                fontColorVar === "Black (Default)" ? "Black" : fontColorVar,
              fontSize:
                fontSizeVar === "Medium (Default)" ? "Medium" : fontSizeVar,
            }}
          >
            Invite colleagues to join
          </Typography>
          <Box
            position="absolute"
            right="0"
            top="50%"
            padding="4px"
            marginRight="8px"
            sx={{
              transform: "translateY(-50%)",
              background: "#F9FAFB",
              border: "1px solid #F2F4F7",
              borderRadius: "4px",
            }}
          >
            ⌘I
          </Box>
        </Box>
        <Box
          position="relative"
          borderRadius="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          padding="8px"
          sx={{ "&:hover": { backgroundColor: "#F2F6FF" } }}
        >
          <ProductUpdateIcon />
          <Typography
            variant="body1"
            sx={{
              color:
                fontColorVar === "Black (Default)" ? "Black" : fontColorVar,
              fontSize:
                fontSizeVar === "Medium (Default)" ? "Medium" : fontSizeVar,
            }}
          >
            Create new document
          </Typography>
          <Box
            position="absolute"
            right="0"
            top="50%"
            padding="4px"
            marginRight="8px"
            sx={{
              transform: "translateY(-50%)",
              background: "#F9FAFB",
              border: "1px solid #F2F4F7",
              borderRadius: "4px",
            }}
          >
            ⌘N
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box padding="16px 8px">
        <Box
          position="relative"
          borderRadius="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          padding="8px"
          sx={{ "&:hover": { backgroundColor: "#F2F6FF" } }}
        >
          <ProfileIcon />
          <Typography
            variant="body1"
            sx={{
              color:
                fontColorVar === "Black (Default)" ? "Black" : fontColorVar,
              fontSize:
                fontSizeVar === "Medium (Default)" ? "Medium" : fontSizeVar,
            }}
          >
            My Profile
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            gap="4px"
            position="absolute"
            right="0"
            top="50%"
            marginRight="8px"
            sx={{
              transform: "translateY(-50%)",
            }}
          >
            <Box
              padding="4px"
              sx={{
                background: "#F9FAFB",
                border: "1px solid #F2F4F7",
                borderRadius: "4px",
              }}
            >
              ⌘K
            </Box>
            <ArrowWithTailRightIcon />
            <Box
              padding="4px"
              sx={{
                background: "#F9FAFB",
                border: "1px solid #F2F4F7",
                borderRadius: "4px",
              }}
            >
              P
            </Box>
          </Box>
        </Box>
        <Box
          borderRadius="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          padding="8px"
          sx={{ "&:hover": { backgroundColor: "#F2F6FF" } }}
        >
          <UserIcon width="17px" color="#344054" />
          <Typography
            variant="body1"
            sx={{
              color:
                fontColorVar === "Black (Default)" ? "Black" : fontColorVar,
              fontSize:
                fontSizeVar === "Medium (Default)" ? "Medium" : fontSizeVar,
            }}
          >
            Team Profile
          </Typography>
        </Box>
        <Box
          borderRadius="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          padding="8px"
          sx={{ "&:hover": { backgroundColor: "#F2F6FF" } }}
        >
          <HiringIcon />
          <Typography
            variant="body1"
            sx={{
              color:
                fontColorVar === "Black (Default)" ? "Black" : fontColorVar,
              fontSize:
                fontSizeVar === "Medium (Default)" ? "Medium" : fontSizeVar,
            }}
          >
            Invite colleagues to join
          </Typography>
        </Box>
        <Box
          borderRadius="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          padding="8px"
          sx={{ "&:hover": { backgroundColor: "#F2F6FF" } }}
        >
          <CreateFolderIcon width="18px" />
          <Typography
            variant="body1"
            sx={{
              color:
                fontColorVar === "Black (Default)" ? "Black" : fontColorVar,
              fontSize:
                fontSizeVar === "Medium (Default)" ? "Medium" : fontSizeVar,
            }}
          >
            Create new project
          </Typography>
        </Box>
        <Box
          borderRadius="6px"
          display="flex"
          alignItems="center"
          gap="8px"
          padding="8px"
          sx={{ "&:hover": { backgroundColor: "#F2F6FF" } }}
        >
          <ContactSupportIcon />
          <Typography
            variant="body1"
            sx={{
              color:
                fontColorVar === "Black (Default)" ? "Black" : fontColorVar,
              fontSize:
                fontSizeVar === "Medium (Default)" ? "Medium" : fontSizeVar,
            }}
          >
            Support
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ProjectOverview;
